import axios from '../../lib/axios';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Dialog } from 'primereact/dialog';
import fileDownload from 'js-file-download';



// download ledger page
export default function DownloadLedger() {
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [todate, setToDate] = useState(null);
    const [fromdate, setFromDate] = useState(null);
    const [ledger, setLedger] = useState();
    const [openingBalance, setOpeningBalance] = useState();
    const [closingBalance, setClosingBalance] = useState();
    // const [showDialog, setShowDialog] = useState(false);
    // const [orderLines, setOrderLines] = useState([]);

    const [generatedList, setGeneratedList] = useState([]);


    useEffect(() => {
        getGeneratedList();
        const att = document.createAttribute("required");
        document.getElementsByTagName("input")[0].setAttributeNode(att)
    }, []);

    const getGeneratedList = () => {
        axios.get(`/api/generated-pdf-list?type=customer_ledger`)
            .then(res => {
                setGeneratedList(res.data);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }


    const searchCustomer = (event) => {
        let _filteredCustomers;
        axios.get(`/api/customer?key=${event.query}`)
            .then(res => {
                _filteredCustomers = res.data.filter((e) => {
                    return e.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
                setCustomer(_filteredCustomers);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }


    function submitEvent(e) {
        e.preventDefault();
        // setLoading(true);
        let from_date = moment(fromdate).toISOString(true);
        let to_date = moment(todate).toISOString(true);
        setTimeout(() => {
            getGeneratedList();
        }, 1000)
        axios.post(`api/customer/generate/ledger-report`, {
            customer_id: selectedCustomer.id,
            from_date: from_date,
            to_date: to_date
        })
            .then(res => {

            }).finally(() => {
                getGeneratedList();
            })
    }

    const downLoadBtnBody = rowData => {
        if (rowData.status === 'success') {
            return <Button type="button" label='download' onClick={() => downLoadFile(rowData)} />
        }
        return null;

    }

    const downLoadFile = (rowData) => {
        axios({
            method: 'get',
            url: `/api/download-pdf/${rowData.id}`,
            responseType: 'blob'
        }).then(res => {
            fileDownload(res.data, rowData.file_name);

        }).catch(error => {
            console.log(error);
            if (error.response?.status !== 409) throw error
        });
    }



    // const itemDetails = rowData => {
    //     return rowData.order_id ? <Button icon="pi pi-eye" onClick={() => showItems(rowData.order_id)} /> : `Paid against${rowData.item}`;
    // }

    // const showItems = order_id => {
    //     axios.get('api/order-lines', {
    //         params: {
    //             order_id: order_id
    //         }
    //     }).then((res) => {
    //         setOrderLines(res.data);
    //         setShowDialog(true);
    //     }).catch(error => { })
    // }

    // const totalPrice = rowData => rowData.qty * rowData.sold_price;

    return (
        <>
            <div>
                <Helmet>
                    <title>Customer Ledger</title>
                </Helmet>
            </div>
            <h2 className='mt-0 flex align-items-center'>PDF: Customer Ledger</h2>
            <form onSubmit={submitEvent} >
                <div className="col-12 p-0">
                    <div className="card p-fluid pb-0">

                        <div className="formgrid grid">

                            <div className="field col">
                                <label htmlFor="name1">Select Customer</label>
                                <AutoComplete className='w-full' placeholder="Select customer" inputId='select_customer' name='customer' value={selectedCustomer} suggestions={customer} completeMethod={searchCustomer} field="name" onChange={(e) => setSelectedCustomer(e.value)} />
                            </div>

                            <div className="field col">
                                <label htmlFor="from Date">From Date</label>
                                <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='fromdate' value={fromdate} onChange={(e) => setFromDate(e.value)} showIcon required />
                            </div>

                            <div className="field col">
                                <label htmlFor="To Date">To Date</label>
                                <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='todate' value={todate} onChange={(e) => setToDate(e.value)} showIcon required />
                            </div>

                            <div className="field flex align-items-end pl-2">
                                <Button label="Generate" type="submit" aria-label="Generate" />
                            </div>
                        </div>

                        <br />

                        <DataTable className='border-bottom-rounded' value={generatedList} responsiveLayout="scroll" loading={loading}>
                            <Column field="from_date" header="From Date"></Column>
                            <Column field="to_date" header="To Date"></Column>
                            <Column field="file_name" header="File Name"></Column>
                            <Column field="status" header="Status"></Column>
                            <Column body={downLoadBtnBody} header="Action"></Column>
                        </DataTable>
                    </div>


                </div>
            </form>


        </>

    )
}