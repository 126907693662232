import React, { useState, memo } from 'react'
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import axios from 'axios';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Message } from "primereact/message";
import { useSelector } from 'react-redux'


// manage users page (create & Edit)
const ManageUsers = ({ isOpen, onHide, user_data, shops, roles }) => {

  const isSuperAdmin = useSelector(e => e.issuperAdmiReducer).isSuperAdmin;

  const [loading, setLoading] = useState(false);
  const [createCustomerErrors, setCreateCustomerErrors] = useState([]);

  let role_data = user_data ? user_data.roles ? user_data.roles.length > 0 ? user_data.roles[0] : '' : '' : ''
  let { pivot, ...rest } = role_data

  const formik = useFormik({
    initialValues: {
      shop_id: user_data ? user_data.shop : "",
      role_id: user_data ? rest : '',
      name: user_data ? user_data.name : '',
      email: user_data ? user_data.email : '',
      password: '',
      password_confirmation: '',
    },
    validate: (data) => {
      let errors = {};

      if (!data.shop_id) {
        errors.shop_id = 'Shop is required.';
      }

      if (!data.role_id) {
        errors.role_id = 'Role is required.';
      }
      if (!data.name) {
        errors.name = 'Name is required.';
      }

      if (!data.email) {
        errors.email = 'Email is required.';
      }

      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(data.email) === false) {
        errors.email = 'Email is not valid.';
      }

      if (!data.password && !user_data) {
        errors.password = 'Password is required';
      }

      if (data.password && data.password.length !== 8 && !user_data) {
        errors.password = 'Password must contains atleast 8 charactors';
      }

      if (!data.password_confirmation && !user_data) {
        errors.password_confirmation = 'Confirm password is required';
      }

      if (data.password_confirmation && data.password_confirmation !== data.password && !user_data) {
        errors.password_confirmation = 'Confirm password and Password should be equal';
      }

      return errors;
    },
    onSubmit: (data) => {
      setLoading(true)
      ////////updation/////////
      if (user_data) {
        let { shop_id, role_id, password, password_confirmation, ...rest } = data
        axios
          .put(`/api/user/${user_data.id}`, {
            data: {
              type: 'user',
              attributes: {
                ...rest, shop_id: shop_id.id
              },
              relationships: {
                roles: {
                  data: [{
                    type: 'role',
                    id: role_id.id
                  }]
                }
              }
            }
          })
          .then((res) => {
            onHide('Updated');
          })
          .catch(error => {
            setLoading(false)
            if (error) {
              if (error.response.status === 400) {
                let err = Object.values(error?.response?.data)
                setCreateCustomerErrors(err)
              }
            } else {
              console.log(error)
            }
          });
        return
      }

      ///////////creation//////////
      let { shop_id, role_id, ...rest } = data
      axios
        .post('/api/user', {
          data: {
            type: 'user',
            attributes: {
              ...rest, shop_id: shop_id.id
            },
            relationships: {
              roles: {
                data: [{
                  type: 'role',
                  id: role_id.id
                }]
              }
            }
          }
        })
        .then((res) => {
          onHide('Created');
        })
        .catch(error => {
          setLoading(false)
          if (error) {
            if (error.response.status === 400) {
              let err = Object.values(error?.response?.data)
              setCreateCustomerErrors(err)
            }
          } else {
            console.log(error)
          }

        });
      
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <>

      <Dialog header={`${user_data ? 'Update User' : 'Create User'}`} visible={isOpen} style={{ width: '450px' }} modal
        className="p-fluid" onHide={onHide}>
        {
          createCustomerErrors.map((val, k) => {
            return (
              <Message severity="error" key={k} text={val} />
            )
          })
        }
        <form onSubmit={formik.handleSubmit} className="p-fluid">

          {/* for super admin only */}

          <div className="field">
            <label htmlFor="role_id">Select Role</label>
            <Dropdown optionLabel='name' id="role_id" name="role_id" options={roles} value={formik.values.role_id} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('role_id') })} />
            {getFormErrorMessage('role_id')}
          </div>

          {
            isSuperAdmin &&
            <>
              <div className="field" >
                <label htmlFor="shop_id">Select Branch</label>
                <Dropdown optionLabel='name' id="shop_id" name="shop_id" options={shops} value={formik.values.shop_id} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('shop_id') })} />
                {getFormErrorMessage('shop_id')}
              </div>
            </>
          }

          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
            {getFormErrorMessage('name')}
          </div>

          <div className="field">
            <label htmlFor="email">Email</label>
            <InputText type={"email"} id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
            {getFormErrorMessage('email')}
          </div>

          {/* ///////hide for user update///////// */}
          {
            !user_data &&
            <>
              <div className="field">
                <label htmlFor="password">Password</label>
                <InputText type={"password"} id="password" name="password" value={formik.values.password} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                {getFormErrorMessage('password')}
              </div>
              <div className="field">
                <label htmlFor="password_confirmation">Confirm Password</label>
                <InputText type={"password"} id="password_confirmation" name="password_confirmation" value={formik.values.password_confirmation} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('password_confirmation') })} />
                {getFormErrorMessage('password_confirmation')}
              </div>
            </>
          }

          <div className='flex justify-content-end'>
            {
              user_data ?
                <Button type="submit" label={`${loading ? "loading.." : "Update User"}`} disabled={loading} />
                :
                <Button type="submit" label={`${loading ? "loading.." : "Create User"}`} disabled={loading} />
            }
          </div>
        </form>
      </Dialog>
    </>
  )
}

export default memo(ManageUsers);