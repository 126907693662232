import React, { useState, useEffect, useRef } from 'react';
import axios from '../../lib/axios';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CreateCustomerDialog from '../orders/components/CreateCustomerDialog';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';
import { captilize } from '../../lib/helper';
import { SplitButton } from 'primereact/splitbutton';
import { Badge } from 'primereact/badge';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

// list customers page

const Customer = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            'attributes.name': { value: '', matchMode: 'contains' },
            'attributes.description': { value: '', matchMode: 'contains' },
            'attributes.cost': { value: '', matchMode: 'contains' },
        }
    });

    const isSuperAdmin = useSelector(e => e.issuperAdmiReducer).isSuperAdmin;
    const [customerCreateDialog, setCustomerCreateDialog] = useState(false);

    useEffect(() => {
        getCustomers();
        return (() => {
            setCustomers([]);
            setTotalRecords(0);
        })
    }, []);

    const getCustomers = async (page = 1) => {
        setLoading(true);
        await axios.get(`/api/customers?page=${page}`)
            .then(res => {
                setCustomers(res.data.data);
                setTotalRecords(res.data.total);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
        setLoading(false);
    }

    const [paginator, setPaginator] = useState(true)


    const onGlobalFilterChange = (e) => {
        const search_keys = e.target.value;
        if (!search_keys) {
            setPaginator(true);
            getCustomers(1);
        } else {
            setPaginator(false)
            axios.get('/api/customer', {
                params: {
                    key: `%${search_keys}%`
                }
            })
                .then((res) => {
                    setCustomers(res.data);
                    setTotalRecords(res.data.length);
                })
                .catch(error => {
                    if (error.response?.status !== 409) throw error
                });
        }
    }
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end align-items-center">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }

    const onPage = async (event) => {
        setLazyParams(event);
        getCustomers(event.page + 1);
    }

    const onSort = (event) => {
        console.log('sort');
        setLazyParams(event);
    }

    const onFilter = (event) => {
        console.log('filter');
        event['first'] = 0;
        setLazyParams(event);
    }

    const header = renderHeader();

    const onCreatedCustomer = (data) => {
        console.log(data);
        setCustomerCreateDialog(false);
        getCustomers();
        // let _customers = [...customers];
        // _customers.push(data);
        // setCustomers(_customers);
    }


    const actionCustomer = rowData => {
        const ActionItems = [
            {
                label: 'Edit',
                icon: 'pi pi-user-edit',
                command: (e) => {
                    updateCustomer(rowData);
                }
            },
            {
                label: !rowData.is_active ? 'Active' : 'Deactive',
                icon: !rowData.is_active ? 'pi pi-check' : 'pi pi-times',
                command: (e) => {
                    confirmDialog({
                        message: 'Are you sure you want to proceed?',
                        header: 'Confirmation',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: !rowData.is_active ? 'Active' : 'Deactive',
                        accept: () => activeOrDeactivate(rowData),
                    });

                }
            }
        ];
        return <SplitButton label="Action" buttonClassName='p-button-sm p-button-text' className="p-button-sm p-button-text" model={ActionItems}></SplitButton>
        // return <Button label="Small" icon="pi pi-check" className="p-button-sm"  />
        // <span className="btn-view" onClick={() => updateCustomer(rowData)}><i className="pi pi-user-edit"></i></span>
    }

    const activeOrDeactivate = async (rowData) => {
        await axios.patch(`/api/customer-activation/${rowData?.id}`)
            .then(res => {
                let _customers = [...customers];
                const index = _customers.findIndex(customer => customer.id === rowData?.id);

                _customers[index]['is_active'] = !rowData?.is_active;
                setCustomers(_customers);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }

    const [customerData, setCustomerData] = useState();
    const [flag, setFlag] = useState(false);

    const updateCustomer = (customer) => {
        setCustomerCreateDialog(true);
        setCustomerData(customer);
        setFlag(true);
    }

    const onUpdateCustomer = (data) => {
        setCustomerCreateDialog(false);
        let _customer = [...customers];

        let index = _customer.findIndex(obj => {
            return obj.id === data.id;
        });
        _customer[index] = data;
        setCustomers(_customer);
        getCustomers();
    };

    const toast = useRef(null);

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Successfull', detail: 'Action Completed', life: 3000 });
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: 'Error ', detail: 'Unexpected Error', life: 3000 });
    }

    const hideCreateCustomerDialog = (success) => {
        if (success == 'Success') {
            showSuccess();
        } else if (success === 'Error') {
            showError();
        }
        setCustomerCreateDialog(false)
    }

    const customerNameBody = rowData => <Link to={{ pathname: `/customer/${rowData.id}` }}>{captilize(rowData.name)}</Link>

    const statusBody = rowData => <Badge value={rowData.is_active ? 'Activated' : 'Deactivated'} severity={rowData.is_active ? 'success' : 'danger'}></Badge>


    const addressBodyTemplate = (rowData) => {
        return (<>
            <p title={rowData.address} style={{whiteSpace:'nowrap',overflow:'hidden',  maxWidth:'100px', textOverflow:'ellipsis'}}>{rowData.address}</p>
        </>);
    };

      
    return (
        <>
            <div>
                <Helmet>
                    <title>Customers</title>
                </Helmet>
            </div>

            <div className='flex justify-content-between mb-4'>
                <h2 className='mt-0 mb-0 flex align-items-center'>Customers</h2>
                <Button label="Create New" icon="pi pi-plus" iconPos="left" onClick={() => { setFlag(false); return setCustomerCreateDialog(true) }} />
            </div>
            <Card className='no-px no-px-2'>
                <ConfirmDialog />
                <div className='custom-table'>
                    <DataTable className='tr-p-datatable-header br-p-paginator-bottom' dataKey="id" value={customers} lazy filterDisplay="row" paginator={paginator} rows={lazyParams.rows}
                        header={header} loading={loading} responsiveLayout="scroll"
                        first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
                        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onFilter={onFilter} filters={lazyParams.filters} globalFilterFields={['name', 'email']}
                        emptyMessage="No customers found.">
                        {/* <Column field="id" header="ID"></Column> */}
                        <Column body={customerNameBody} header="Name"></Column>
                        {isSuperAdmin &&
                            <Column field="shop.name" header="Branch"></Column>
                        }
                        <Column field="email" header="Email"></Column>
                        <Column field="agent_customer.collection_agent.name" header="Customer Agent"></Column>
                        <Column field="address" header="Address"  body={addressBodyTemplate} ></Column>
                        <Column field="opening_balance" header="Opening Balance"></Column>
                        <Column body={statusBody} header="Status"></Column>
                        <Column body={actionCustomer} header="Action"></Column>
                    </DataTable>
                </div>
                <Toast ref={toast} />
            </Card>

            {
                customerCreateDialog &&
                <CreateCustomerDialog visible={customerCreateDialog} onCreatedCustomer={onCreatedCustomer}
                    onHide={hideCreateCustomerDialog} customerdata={customerData} flag={flag} onUpdateCustomer={onUpdateCustomer} roles={roles} isSuperAdmin={isSuperAdmin} />
            }
        </>
    );
}

export default Customer;
