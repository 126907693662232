
const numericOnly = value => {
    const reg = /^\d*\.?\d*$/;
    return reg.test(value);
}

const lettersOnly = value => {
    const reg = /^[a-zA-Z]+$/;
    return reg.test(value);
}

const captilize = string => string[0].toUpperCase() + string.slice(1);

export {
    numericOnly,
    lettersOnly,
    captilize
}