import React, { useState, useEffect, useRef } from 'react'
import axios from "../../lib/axios";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';;
import ShopDialog from './ShopDialog';
import { Toast } from 'primereact/toast';
import { Helmet } from 'react-helmet';


// list shops page
function Shop() {

    const [loading, setLoading] = useState(false);
    const [shops, setShops] = useState([]);

    const [showDialog, setshowDialog] = useState(false);

    useEffect(() => {
        fetchShops()

        return(()=>{
            setShops([]);
        })
    }, [])

    const fetchShops = async () => {
        setLoading(true);
        await axios.get(`/api/shop`)
            .then(res => {
                setShops(res.data);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
        setLoading(false);
    }

    const hideDialog = (val) => {
        if (val === 'Updated') {
            showSuccessToast();
        }
        else if (val === 'Error') {
            showErrorToast();
        }
        setshowDialog(false)
    }
    const toast = useRef(null);

    const showSuccessToast = () => {
        fetchShops()
        toast.current.show({ severity: 'success', summary: 'Success Message', detail: `Shop Successfully Created`, life: 3000 });
    };

    const showErrorToast = () => {
        toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Error', life: 3000 });
    }

    const editBranch = rowData => <Button className="p-button-secondary" onClick={() => updateBranch(rowData)}><i className="pi pi-user-edit"></i></Button>
    const [shopdata, setShopData] = useState();
    const [updateFlag, setUpdateFlag] = useState(false);
    const updateBranch = (data) => {
        setUpdateFlag(true)
        setShopData(data)
        setshowDialog(true)
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Branches</title>
                </Helmet>
            </div>

            <div className='flex justify-content-between mb-4'>
                <h2 className='mt-0 mb-0 flex align-items-center'>Branches</h2>
                <Button label="Create New" icon="pi pi-plus" iconPos="left" onClick={() => { setUpdateFlag(false); return setshowDialog(true) }} />
            </div>
            <Card className='no-px no-px-2'>
                
                <div className='custom-table'>
                <DataTable dataKey="id" value={shops} lazy filterDisplay="row"
                    loading={loading} responsiveLayout="scroll"
                    globalFilterFields={['name', 'description']}
                    emptyMessage="No branches found.">
                    {/* <Column field="id" header="ID"></Column> */}
                    <Column field="name" header="Name"></Column>
                    <Column field="address" header="Address"></Column>
                    <Column field="phone" header="Phone"></Column>
                    <Column field="Action" body={editBranch} header="Edit"></Column>
                </DataTable>
                </div>

                {showDialog && <ShopDialog isOpen={showDialog} onHide={hideDialog} updateFlag={updateFlag} shopdata={shopdata} />}
                <Toast ref={toast} />

            </Card>
        </>
    )
}

export default Shop