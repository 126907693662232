import * as React from "react";
import "./componentToPrint.css"
import invoice from '../../../../images/invoice.png'
const ComponentToPrint = React.forwardRef((props, ref) => {
  let prop = props?.generatedInvoice
  return (
    <section ref={ref} className="invoice">
      <header className="invoice-header">
        <div className="invoice-header__left">
          <img src={invoice} />
          <p className="invoice-header__left-title">INVOICE</p>
        </div>
        <div className="invoice-header__right">
          <div className="invoice-header__right-item">
            <p>123-456-7890</p>
            <p>info@ozo.com</p>
            <p>ozo.com</p>
          </div>
          <div className="invoice-header__right-item">
            <p>Your Address</p>
            <p>City,State,Country</p>
            <p>ZIP CODE</p>
          </div>
        </div>
      </header>
      <div className="invoice-body">
        <div className="invoice-body__top">
          <div className="invoice-body__top-left">
            <div className="invoice-body__top-left-item-left">
              <p className="invoice-text__secondary">Billed To</p>
              <p className="invoice-text__primary">{prop?.customer_name}</p>
              {/* <p className="invoice-text__primary">Client Address</p>
                <p className="invoice-text__primary">City,State,Country</p>
                <p className="invoice-text__primary">ZIP CODE</p> */}
            </div>
            <div className="invoice-body__top-left-item-right">
              <div className="invoice-number">
                <p className="invoice-text__secondary">Invoice Number</p>
                <p className="invoice-text__primary">{prop?.invoice_number}</p>
              </div>
              <div className="invoice-date">
                <p className="invoice-text__secondary">Date of Issue</p>
                <p className="invoice-text__primary">{prop?.created_at?.slice(0, 10)}</p>
              </div>
            </div>
          </div>
          <div className="invoice-body__top-right">
            <p className="invoice-text__secondary">Invoice Total</p>
            <p className="invoice-text__price">{prop?.total}</p>
          </div>
        </div>
        <table className="invoice-body__table">
          <thead>
            <tr>
              <th className="invoice-body__table-left">Item</th>
              <th className="invoice-body__table-left">Description</th>
              <th>Unit Cost</th>
              <th>Qty / Hr Rate</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {
              prop?.items?.length ? prop.items.map((i, k) => {
                return (
                  <tr key={k}>
                    <td className="invoice-body__table-left">
                      <p className="invoice-text__primary">{i.item}</p>
                    </td>
                    <td className="invoice-body__table-left">
                      <p className="invoice-text__primary">{i.description}</p>
                    </td>
                    <td><p className="invoice-text__secondary">{i.price}</p></td>
                    <td><p className="invoice-text__secondary">{i.qty}</p></td>
                    <td><p className="invoice-text__secondary">{parseFloat(i.qty * i.price).toFixed(2)}</p></td>
                  </tr>
                )
              }) : ''
            }

            {prop?.extra_charge_amt != 0 &&
              <>
                <tr>
                  <td colSpan="4">
                    <p className="invoice-text__primary text-left">{prop.extracharge_desc}</p>
                  </td>
                  <td>
                    <p className="invoice-text__secondary">{Number.parseFloat(prop.extra_charge_amt).toFixed(2)}</p>
                  </td>
                </tr>
              </>
            }

          </tbody>
        </table>
      </div>
      <footer className="invoice-footer">
        <div className="invoice-footer__left">
          <p className="invoice-text__secondary">
            Remark : {prop?.remarks}
          </p>
        </div>
        <div className="invoice-footer__right">
          <div className="invoice-footer__right-item">
            <p className="invoice-text__colored">Subtotal</p>
            <p className="invoice-text__colored">Discount</p>
            <p className="invoice-text__colored">Total</p>
          </div>
          <div className="invoice-footer__right-item">
            <p className="invoice-text__primary">{prop?.sub_total}</p>
            <p className="invoice-text__primary">{prop?.discount}</p>
            <p className="invoice-text__primary">{prop?.total}</p>
          </div>
        </div>
      </footer>
    </section>
  );
});

export default ComponentToPrint