import React,{useRef} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import axios from '../lib/axios';
import { useSelector } from 'react-redux';
import './apptopbar.css';
import logo from '../../../images/logo-header.png';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';


export const AppTopbar = (props) => {
    const [displayBasic, setDisplayBasic] = useState(false);
    const [userloged, setUserLoged] = useState(true);
    const history = useHistory();

    const LogoutAction = () => {
        console.log('logout : ');
        setDisplayBasic(true);
    };

    const onHide = () => {
        setDisplayBasic(false);
    }

    const Logout = () => {
        axios.post('logout')
            .then(e => {
                window.location.href = "login";
            })
    };

    const menu = useRef(null);

    let items = [
        {label: 'Customer Ledger', icon: 'pi pi-fw pi-folder-open', command:()=>{history.push('/customer-ledger')}},
        {label: 'Stock Transfer Details', icon: 'pi pi-fw pi-chart-line', command:()=>{history.push('/stock-transfer-details')}},
        {label: 'Damage Entries', icon: 'pi pi-fw pi-exclamation-triangle', command:()=>{history.push('/damage-entries')}},
        {label: 'Payment History', icon: 'pi pi-fw pi-history', command:()=>{history.push('/payments/history')}},
        {label: 'Purchase Details', icon: 'pi pi-fw pi-tag', command:()=>{history.push('/purchase-details')}}
    ];

    const userState = useSelector(e => e.issuperAdmiReducer);
    return (
        <div className="layout-topbar">
            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <Link to="/" className="layout-topbar-logo">
                <img src={logo} className="ml-5" alt="logo" />
            </Link>

            <div className='nav-links'>
            <Button className='nav-button' label="Orders" aria-label="Submit" onClick={() => history.push('/orders')} />
            
            <Menu model={items} popup ref={menu} id="popup_menu" />
                <Button className='nav-button' label="Reports" icon="pi pi-angle-down" iconPos="right" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
            </div>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li>
                    <h5 className='mb-0'>  {userState.data.user.name} </h5>
                    <span>  {userState.data.user.role} </span>
                </li>

                <li>
                    <button className="p-link layout-topbar-button" onClick={LogoutAction}>
                        <i className="pi pi-user" />
                        <span>Profile</span>
                        <span>  {userState.data.user.role} </span>
                    </button>

                    <div className='dialog_box'>
                        <Dialog header="User" visible={displayBasic} className='user' onHide={() => onHide()}>
                            <p onClick={Logout} className='logout_label'>Logout</p>
                        </Dialog>
                    </div>
                </li>
            </ul>
        </div>
    );
}
