import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../lib/axios';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Toast } from 'primereact/toast';


// payment history page
const PaymentHistory = () => {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [customer, setCustomer] = useState([]);
    const toasts = useRef(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedPayments, setSelectedPayments] = useState(null);

    const searchCustomer = (event) => {
        let _filteredCustomers;
        axios.get(`/api/customer?key=${event.query}`)
            .then(res => {
                _filteredCustomers = res.data.filter((e) => {
                    return e.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
                setCustomer(_filteredCustomers);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }

    const getPaymentHistory = () => {

        if (!selectedCustomer?.id) {
            
            return;
        }

        if (!fromDate) {
            toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'From date required' });
            return;
        }

        if (!toDate) {
            toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'To date required' });
            return;
        }

        setLoading(true);
        axios.get('api/payments/history', {
            params: {
                customer_id: selectedCustomer.id,
                fromDate: moment(fromDate).toISOString(true),
                toDate: moment(toDate).toISOString(true)
            }
        })
            .then(res => {
                setPaymentHistory(res.data.data);
            }).catch(error => {
                toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong' });
            }).finally(() => {
                setLoading(false);
            })
    }

    const onSelectionChange = (event) => {
        const value = event.value;
        setSelectedPayments(value);
        setSelectAll(value.length === totalRecords);
    }

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            setSelectAll(true);
            setSelectedPayments(paymentHistory);
        }
        else {
            setSelectAll(false);
            setSelectedPayments([]);
        }
    }

    const getOneItemAmount = rowData => rowData.amount / rowData.qty;

    return (
        <>
            <Helmet>
                <title>Customer Payment History</title>
            </Helmet>

            <h2 className='mt-0 flex align-items-center mr-auto'>Payment History</h2>
            <div className='card-box'>
            <Card>
                <div className="formgrid grid">

                    <div className="field col">
                        <label htmlFor="select_customer">Select Customer</label><br />
                        <AutoComplete className='w-full' placeholder="Select customer" inputId='select_customer' name='customer' value={selectedCustomer} suggestions={customer} completeMethod={searchCustomer} field="name" onChange={(e) => setSelectedCustomer(e.value)} />
                    </div>

                    <div className="field col">
                        <label htmlFor="from Date">From Date</label>
                        <Calendar className='w-full' id="icon" name='fromdate' value={fromDate} onChange={(e) => setFromDate(e.value)} showIcon required />
                    </div>

                    <div className="field col">
                        <label htmlFor="To Date">To Date</label>
                        <Calendar className='w-full' id="icon" name='todate' value={toDate} onChange={(e) => setToDate(e.value)} showIcon required />
                    </div>

                    <div className="field flex align-items-end pl-2">
                        <Button label="Submit" className="" type="button" aria-label="Submit" onClick={getPaymentHistory} />
                    </div>


                </div>
                <DataTable value={paymentHistory} lazy responsiveLayout="scroll" loading={loading}
                    selection={selectedPayments} onSelectionChange={onSelectionChange}
                    selectAll={selectAll} onSelectAllChange={onSelectAllChange} emptyMessage="No payments" >
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="date" header="Date"></Column>
                    <Column field="order_number" header="Order Number"></Column>
                    <Column field="item" header="Item"></Column>
                    <Column field="qty" header="Qty"></Column>
                    <Column body={getOneItemAmount} header="Amount"></Column>
                    <Column field="amount" header="Total"></Column>
                </DataTable>

                <Toast ref={toasts} />
            </Card>
            </div>
        </>
    );
}

export default PaymentHistory;
