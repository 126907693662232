import { Helmet } from 'react-helmet';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReportCard from '../components/ReportCard';
import React, { useEffect, useState } from 'react';
import axios from '../lib/axios';

export default function Stats() {
    const history = useHistory();
    const isSuperAdmin = useSelector(e => e.issuperAdmiReducer).isSuperAdmin;

    const [monthReport, setMonthReport] = useState({
        total_collection: 0,
        total_orders: 0,
        total_sales: 0
    });

    useEffect(() => {
        getReports();
    }, []);

    const getReports = () => {
        axios.get('api/month-report')
            .then(res => setMonthReport(res.data))
            .catch(error => {

            })
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
            </div>
            
            <h2 className='mt-0 flex align-items-center'>Dashboard</h2>
            <Card className="mb-3 no-px">
                <div className="p-fluid">
                    <div className="flex flex-row flex-wrap gap-4">
                        {!isSuperAdmin &&

                            <>
                                
                                <div className="flex align-items-center justify-content-center mr-4">
                                    <Button label="Orders" aria-label="Submit" icon='pi pi-fw pi-shopping-cart' onClick={() => history.push('/orders')} />
                                </div>

                                <div className="flex align-items-center justify-content-center mr-4">
                                    <Button label="Stock Transfer" aria-label="Submit" icon='pi pi-fw pi-sort-amount-up-alt' onClick={() => history.push('/stock-transfer')} />
                                </div>

                                <div className="flex align-items-center justify-content-center mr-4">
                                    <Button label="Purchase" aria-label="Submit" icon='pi pi-fw pi-shopping-bag' onClick={() => history.push('/inventory')} />
                                </div>
                            </>
                        }

                        <div className="flex align-items-center justify-content-center mr-4">
                            <Button label="Customers" aria-label="Submit" icon='pi pi-fw pi-users' onClick={() => history.push('/customers')} />
                        </div>



                        {isSuperAdmin &&
                            <div className="flex align-items-center justify-content-center mr-4">
                                <Button label="Stock Transfer" aria-label="Submit" icon='pi pi-fw pi-sort-amount-up-alt' onClick={() => history.push('/stock-transfer-details')} />
                            </div>}

                        <div className="flex align-items-center justify-content-center ">
                            <Button label="Products" aria-label="Submit" icon='pi pi-fw pi-box' onClick={() => history.push('/products/create')} />
                        </div>
                    </div>
                </div>
            </Card>

            <div className="grid mt-2">
                <div className="col-12 lg:col-6 xl:col-4">
                    <ReportCard title="Total Order This Month" value={monthReport?.total_orders} icon="pi pi-shopping-cart text-cyan-500 text-xl" />
                </div>

                <div className="col-12 lg:col-6 xl:col-4">
                    <ReportCard title="Total Sales This Month" value={'KWD ' + monthReport?.total_sales} icon="pi pi-dollar text-cyan-500 text-xl" />
                </div>

                <div className="col-12 lg:col-6 xl:col-4">
                    <ReportCard title="Total Collection This Month" value={'KWD ' + monthReport?.total_collection} icon="pi pi-book text-cyan-500 text-xl" />
                </div>
            </div>
            
            {/* <Card className="mt-2 card-outer" title="Reports">
                <div className="p-fluid">
                    <div className="flex flex-row flex-wrap justify-content-between gap-3">

                        <div className="flex align-items-center justify-content-center mb-2">
                            <Button label="Customer Ledger" aria-label="Submit" icon='pi pi-fw pi-folder-open' onClick={() => history.push('/customer-ledger')} />
                        </div>

                        <div className="flex align-items-center justify-content-center mb-2">
                            <Button label="Stock Transfer Details" aria-label="Submit" icon='pi pi-fw pi-chart-line' onClick={() => history.push('/stock-transfer-details')} />
                        </div>

                        <div className="flex align-items-center justify-content-center mb-2">
                            <Button label="Damage Entries" aria-label="Submit" icon='pi pi-fw pi-exclamation-triangle' onClick={() => history.push('/damage-entries')} />
                        </div>

                        {!isSuperAdmin &&
                            <>
                                <div className="flex align-items-center justify-content-center mb-2">
                                    <Button label="Payment History" aria-label="Submit" icon='pi pi-fw pi-history' onClick={() => history.push('/paymeents/history')} />
                                </div>

                                <div className="flex align-items-center justify-content-center mb-2">
                                    <Button label="Purchase Details" aria-label="Submit" icon='pi pi-fw pi-tag' onClick={() => history.push('/purchase-details')} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Card> */}
        </>
    )
}
