import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React,{memo} from 'react';


// order list view
const OrderLinesView = ({visible, orderLines = [], onHide}) => {
    return (
        <div>
            <Dialog header="Order Items" visible={visible} style={{ width: '50vw' }} onHide={onHide}>
                <DataTable value={orderLines} responsiveLayout="scroll">
                    <Column field="item" header="Item"></Column>
                    <Column field="description" header="Description"></Column>
                    <Column field="qty" header="Quantity"></Column> 
                    <Column field="sold_price" header="Price"></Column>
                </DataTable>
            </Dialog>
        </div>
    );
}

export default memo(OrderLinesView);
