import { capitalize } from 'lodash';
import React,{ useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../lib/axios';
import { Card } from 'primereact/card';


// display customer details page
const CustomerDetails = () => {
    let { id } = useParams();
    const [customer,  setCustomer] = useState({});
    const [loading,  setLoading] = useState(true);

    useEffect(() => {
        axios.get(`api/customer/${id}`)
        .then(response => setCustomer(response.data))
        .catch(error => alert('Somethig went wrong'))
        .finally(() => setLoading(false))

        return (() => {
            setCustomer({})
        })
    },[]);

    
    return (
        <Card title={capitalize(customer?.name)} subTitle="Details & Activities">
            <table>
                <tr>
                    <th className='text-left p-2'>Name</th>
                    <td className="p-2">: {capitalize(customer?.name)}</td>
                </tr>
                <tr>
                    <th className='text-left p-2'>Email</th>
                    <td className="p-2">: {capitalize(customer?.email)}</td>
                </tr>
                <tr>
                    <th className='text-left p-2'>Phone</th>
                    <td className="p-2">: {capitalize(customer?.phone)}</td>
                </tr>
                <tr>
                    <th className='text-left p-2'>Branch</th>
                    <td className="p-2">: {capitalize(customer?.shop?.name)}</td>
                </tr>
                <tr>
                    <th className='text-left p-2'>Address</th>
                    <td className='p-2'>: {capitalize(customer?.address)}</td>
                </tr>
                <tr>
                    <th className='text-left p-2'>Created At</th>
                    <td>: {customer?.created_at}</td>
                </tr>
                <tr>
                    <th className='text-left p-2'>Last Updated At</th>
                    <td className='p-2'>: {customer?.updated_at}</td>
                </tr>
            </table>
        </Card>
    );
}

export default CustomerDetails;
