import React, { memo } from 'react'
import { Dialog } from 'primereact/dialog';


const PreviewDialog = ({ isOpen, onHide, invoiceItems }) => {

    return (
        <Dialog header="Preview Items" visible={isOpen} style={{ width: '590px' }} modal
            className="p-fluid" onHide={onHide}>
            <div className="formgrid grid">
                <div className="field col-5 font-semibold">Product</div>
                <div className="field col-1 font-semibold">Qty</div>
                <div className="field col-2 font-semibold">Inc Cargo Price</div>
                {/* <div className="field col-2 font-semibold">Inc Cargo </div> */}
                <div className="field col-2 font-semibold">Total</div>
            </div>

            {invoiceItems.map((item, index) => {
                return (
                    <div className="formgrid grid" key={index}>
                        <div className="field col-5">
                            {item.product.name}
                        </div>
                        <div className="field col-1">
                            {item.qty}
                        </div>
                        <div className="field col-2">
                            {item._price}
                        </div>
                        {/* <div className="field col-2">
                            {item.cargo_rate}
                        </div> */}
                        <div className="field col-2">
                            {item._total}
                        </div>

                    </div>
                )
            })}
        </Dialog>
    )
}

export default memo(PreviewDialog);