import React, { useState, useRef, memo, useEffect } from 'react'
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import { classNames } from 'primereact/utils';
import { numericOnly, lettersOnly } from '../../lib/helper';

// manage currencies page
const ManageCurrency = ({ isOpen, onHide, currency_data, currencyUpdated, flag }) => {
    const [loading, setLoading] = useState(false);

    const createCurrency = (data) => {
        setLoading(true);
        axios.post(`api/currencies`, {
            data: {
                type: "currencies",
                attributes: {
                    currency: data.currency,
                    kuwait_value: data.kuwait_value
                }
            }
        })
            .then(res => {
                setLoading(false);
                currencyUpdated(res.data);
                onHide('Updated');
            })
            .catch(error => {
                alert(error.response.data.errors.currency);
                setLoading(false);
                onHide('Error');
            })

    }

    const formik = useFormik({
        initialValues: {
            id: currency_data ? currency_data.id : '',
            currency: currency_data ? currency_data.name : '',
            kuwait_value: currency_data ? currency_data.kuwait_value : ''
        },
        validate: (data) => {
            let errors = {}

            if (!data.currency) {
                errors.currency = 'currency is required.';
            }
            if (!data.kuwait_value) {
                errors.kuwait_value = 'kuwait value is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            flag ? createCurrency(data) : handleSubmit(data);
        }
    });

    const handleSubmit = (data) => {
        setLoading(true)
        axios.patch(`api/currencies/${data.id}`, {
            data: {
                type: "currencies",
                attributes: {
                    currency: data.currency,
                    kuwait_value: data.kuwait_value
                }
            }
        })
            .then(res => {
                setLoading(false);
                currencyUpdated(res.data);
                onHide('Updated');
            })
            .catch(error => {
                onHide('Error');
            })
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>

            <Dialog header={`currency`} visible={isOpen} style={{ width: '450px' }} modal className="p-fluid" onHide={onHide}>

                <form onSubmit={formik.handleSubmit} className="p-fluid">

                    <div className="field" style={{ display: 'none' }}>
                        <label htmlFor="id">id</label>
                        <span className="p-float-label">
                            <InputText id="id" name="id" value={formik.values.id} readOnly />
                        </span>
                    </div>

                    <div className="field">
                        <label htmlFor="currency" className={classNames({ 'p-error': isFormFieldValid('currency') })}>Currency</label>
                        <span className="p-float-label">
                            <InputText id="currency" name="currency" value={formik.values.currency} onChange={(e) => {
                                if (lettersOnly(e.target.value) || e.target.value === '') {
                                    formik.handleChange(e)
                                }
                            }}
                                autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                        </span>
                        {getFormErrorMessage('currency')}
                    </div>

                    <div className="field">
                        <label htmlFor="kuwait_value" className={classNames({ 'p-error': isFormFieldValid('kuwait_value') })}>Kuwait Value</label>
                        <span className="p-float-label">
                            <InputText id="kuwait_value" name="kuwait_value" value={formik.values.kuwait_value} onChange={e => numericOnly(e.target.value) && formik.handleChange(e)} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('kuwait_value') })} />
                        </span>
                        {getFormErrorMessage('kuwait_value')}
                    </div>

                    <div className='flex justify-content-end'>
                    <Button type="submit" label={`${loading ? 'loading..' : "Submit"} `} className="mt-2" />
                    </div>

                </form>
            </Dialog>
        </>
    )
}

export default ManageCurrency;