import axios from 'axios';
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import Supplier from './Supplier';

// Manage supplier
function ManageSupplier(props) {
    const [suppliernName, setSuppliernName] = useState();
    const [supplierId, setSupplierId] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (props.editflag && props.details) ? setSuppliernName(props.details.name) : '';
        (props.editflag && props.details) ? setSupplierId(props.details.id) : '';
    }, [props.id]);

    const handleSubmit = (data) => {
        data.preventDefault();
        setLoading(true);
        if (props.editflag) {
            axios.patch(`api/suppliers/${supplierId}`, {
                data: {
                    type: "suppliers",
                    attributes: {
                        name: suppliernName
                    }
                }
            })
                .then(res => {
                    setLoading(false);
                    props.onHide('Created');
                })
                .catch(error => {
                    props.onHide('Error')
                })
        } else {
            setLoading(true);
            axios.post(`api/suppliers`, {
                data: {
                    type: "suppliers",
                    attributes: {
                        name: suppliernName
                    }
                }
            })
                .then(res => {
                    console.log('res : ', res);
                    props.onHide('Created');
                    setLoading(false)
                })
                .catch(error => {
                    props.onHide('Error')
                })
        }
    }

    return (
        <>
            <Dialog header={'Supplier details'} visible={props.isOpen} onHide={props.onHide} >
                <form className="p-fluid" onSubmit={handleSubmit}>
                    {/* {
                        props.editflag &&
                        <>
                            <div className="field">
                                <label htmlFor="id">Id</label>
                                <InputText id="id" name="id" value={supplierId} onChange={(e) => setSupplierId(e.target.value)} readOnly />
                            </div>
                        </>
                    } */}
                    <div className="field">
                        <label htmlFor="name">Name</label>
                        <InputText id="name" name="name" value={suppliernName} onChange={(e) => setSuppliernName(e.target.value)} />
                    </div>
                    <div className='flex justify-content-end'>
                    <Button type="submit" label={`${loading ? "Loading..." : props.editflag ? "Update Supplier" : "Create New Supplier"}`} disabled={loading} />
                    </div>
                </form>
            </Dialog>
        </>
    )
}

export default ManageSupplier