import axios from '../../lib/axios';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect } from 'react'
import { Formik, useFormik } from 'formik';
import ManageStockDetails from './ManageStockDetails';
import moment from 'moment';
import { AutoComplete } from 'primereact/autocomplete';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { capitalize } from 'lodash';


// display single stock transfer details page
function StockTransferDetails() {

    const isSuperAdmin = useSelector(e => e.issuperAdmiReducer).isSuperAdmin;

    const [selectedShop, setSelectedShop] = useState(null);
    const [shop, setShop] = useState([]);

    const searchshop = (event) => {
        let _filteredShop;
        axios.get(`/api/shop?key=${event.query}`)
            .then(res => {
                _filteredShop = res.data.filter((e) => {
                    return e.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
                setShop(_filteredShop);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }

    const [result, setResult] = useState();
    const [loading, setLoading] = useState(false);
    const [showDialog, setshowDialog] = useState(false);
    const [product_data, setProductData] = useState(false);


    const handleSubmit = (data) => {
        let from_date = moment(data.from_date).toISOString(true);
        let to_date = moment(data.to_date).toISOString(true);

        setLoading(true);
        axios.get(`/api/stock-transfers`, {
            params: {
                data: {
                    type: "stock-transfers",
                    attributes: {
                        shop: selectedShop?.id,
                        from_date: from_date,
                        to_date: to_date
                    }
                }
            }
        })
            .then(res => {
                setResult(res.data.data);
                setLoading(false)
            })
            .catch(error => console.log(error))
    }
    const formik = useFormik({
        initialValues: {
            shop_id: null,
            from_date: null,
            to_date: null
        },
        validate: (data) => {
            let errors = {};

            if (data.from_date === null) {
                errors.from_date = 'date required'
            }
            if (data.to_date === null) {
                errors.to_date = 'date required'
            }

            return errors;
        },
        onSubmit: (data) => {
            handleSubmit(data)
            // formik.resetForm();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const stockDetails = rowData => <span style={{ cursor: 'pointer' }} onClick={() => showItems(rowData)}><i className="pi pi-eye"></i></span>
    const showItems = (product) => {
        setProductData(product);
        setshowDialog(true);
    };

    const hideCreateCustomerDialog = (success) => {
        setshowDialog(false)
    }
    
    const transferredByBody = rowData => capitalize(rowData.transferred_by);


    return (
        <>
            <div>
                <Helmet>
                    <title>Stock Transfer Details</title>
                </Helmet>
            </div>
            <h2 className='mt-0 flex align-items-center'>Stock Transfer Details</h2>
            <div className='card-box'>
            <Card className='no-pb'>

                <div className="col-12 p-0">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="formgrid grid">
                            {
                                isSuperAdmin &&
                                <div className="field col mb-5">
                                    <label htmlFor="name1">Select Branch</label>
                                    <AutoComplete className='w-full' placeholder="Select Shop" inputId='select_shop' name='shop' value={selectedShop} suggestions={shop} completeMethod={searchshop} field="name" onChange={(e) => setSelectedShop(e.value)} />
                                    {getFormErrorMessage('shop_id')}
                                </div>
                            }

                            <div className="field col mb-5">
                                <label htmlFor="from Date">From Date</label>
                                <Calendar className='w-full' dateFormat="dd/mm/yy" id="icon" name='from_date' value={formik.values.from_date} onChange={formik.handleChange} showIcon />
                                {getFormErrorMessage('from_date')}
                            </div>

                            <div className="field col mb-5">
                                <label htmlFor="To Date">To Date</label>
                                <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='to_date' value={formik.values.to_date} onChange={formik.handleChange} showIcon />
                                {getFormErrorMessage('to_date')}
                            </div>

                            <div className="field flex align-items-end pl-2 mb-5">
                                <br />
                                <Button label="Submit" type="submit" aria-label="Submit" style={{ marginTop: '5px' }} />
                            </div>

                        </div>
                        <div className="formgrid">
                            <DataTable className='br-p-datatable-wrapper' value={result} responsiveLayout="scroll" emptyMessage="No Stock Transfer Details found." loading={loading} >
                                {/* <Column field="id" header="id"></Column> */}
                                <Column field="created_at" header="Date"></Column>
                                <Column field="from_shop" header="From Shop"></Column>
                                <Column field="to_shop" header="To Shop"></Column>
                                <Column field="total" header="Total"></Column>
                                <Column body={transferredByBody} header="Transferred By"></Column>
                                <Column field="Action" body={stockDetails} header="Show Details"></Column>
                            </DataTable>

                            {showDialog && <ManageStockDetails isOpen={showDialog} onHide={hideCreateCustomerDialog} product_data={product_data} />}

                        </div>


                    </form>
                </div>
            </Card>
            </div>
        </>

    )
}

export default StockTransferDetails