import React, { useState, useEffect, useRef } from 'react'
import { Card } from 'primereact/card';
import { useFormik } from 'formik';
import { AutoComplete } from 'primereact/autocomplete';
import axios from "../../lib/axios";
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';


// list all stock transfers
function StockTransfer() {

    const [shops, setShops] = useState([]);
    const [productItems, setProductItems] = useState([
        {
            product: '',
            qty: 1,
        }
    ]);

    const userState = useSelector(e => e.issuperAdmiReducer).data;
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);

    const [receiverShop, setReceiverShop] = useState('');


    useEffect(() => {
        populateShopsData()

        return(()=>{
            setFilteredProducts([]);
            setShops([]);
        })
    }, [])

    const addField = () => {
        let newTodos = [...productItems];
        newTodos.push({
            product: '',
            qty: 1,
        });
        setProductItems(newTodos);
    }


    const removeField = (index) => {
        let newTodos = [...productItems];
        newTodos.splice(index, 1);
        setProductItems(newTodos);
    }

    const populateShopsData = async () => {
        await axios.get(`/api/shop`)
            .then(res => {
                let _shops = res.data.filter(shop => (shop.name != userState.user.shop));
                setShops(_shops);
            })
            .catch(error => {
                console.log(error);
                if (error.response?.status !== 409) throw error
            });
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    const formik = useFormik({
        initialValues: {
            receiverShop: "",
            senderShop: "",

        },
        validate: (data) => {
            let errors = {};

            if (!receiverShop) {
                errors.receiverShop = 'Receiver Shop is required.';
            }
            if (data.senderShop && data.senderShop && data.senderShop.id === data.receiverShop.id) {
                errors.senderShop = 'Shops are should be unique';
                errors.receiverShop = 'Receiver Shop is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            if (productItems.some((val) => val.product === '')) {
                toast.current.show({ severity: 'warn', summary: 'Product name required', detail: 'Pruduct name cannot be emty', life: 3000 });
            } else {
                setLoading(true)
                let arr = []
                for (let i of productItems) {
                    let obj = { type: 'product' }
                    obj.id = i.product.id
                    obj.qty = i.qty
                    arr.push(obj)
                }
                axios
                    .post('/api/stock-transfer', {
                        data: {
                            type: 'stock-transfer',
                            attributes: {
                                remarks: 'test'
                            },
                            relationships: {
                                receiverShop: {
                                    data: {
                                        type: "shop",
                                        id: receiverShop.id
                                    }
                                },
                                products: {
                                    data: arr
                                }
                            }
                        }
                    })
                    .then((res) => {
                        setProductItems([{
                            product: '',
                            qty: 1,
                        }])
                        setLoading(false)
                        formik.resetForm();
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Completed', life: 3000 });

                    })
                    .catch(error => {
                        console.log(error);
                        setLoading(false)
                    });
            }
        }
    });

    const searchProduct = (event) => {
        axios
            .get('/api/product', {
                params: {
                    key: `%${event.query}%`
                }
            })
            .then((res) => {
                setFilteredProducts(res.data.data);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }

    const searchShop = (event) => {
        axios.get(`/api/search-shop?key=${event.query}`)
            .then(res => {
                let _shops = res.data.filter(shop => (shop.name != userState.user.shop));
                setShops(_shops);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }


    const setInvoiceValues = (e, index, name) => {
        let newProducts = [...productItems];
        if(productItems[index].product.stock < e.target.value){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Product is outstock', life: 3000 });
            return;
        }
        newProducts[index][name] = e.target.value
        setProductItems(newProducts);
    }

    const setInvoiceProductValue = (e, index) => {
        let newProducts = [...productItems];

        if(typeof e.value === 'object'){
            if(e.value.stock < 1){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Product is outstock', life: 3000 });
                return;
            }
        }
        newProducts[index]['product'] = e.value;
        setProductItems(newProducts);
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>Stock Transfer</title>
                </Helmet>
            </div>
            <h2 className='mt-0 flex align-items-center'>Stock Transfer</h2>
            <div className='card-box'>
            <Card>
                <form onSubmit={formik.handleSubmit}>

                    <div className="formgrid grid">
                        <div className="field col-12 mb-1">
                            <label htmlFor="receiverShop">Transfer To</label><br />

                            <AutoComplete className='w-full' placeholder="Search Branch" value={receiverShop} suggestions={shops} completeMethod={searchShop} field="name" onChange={(event) => setReceiverShop(event.value)} />
                            <br/>
                            {getFormErrorMessage('receiverShop')}
                        </div>
                    </div>

                    <Divider />


                    <div className='formgrid grid ml-1'>
                        {productItems.map((item, index) => {
                            return (
                                <div className="formgrid grid w-full" key={index}>
                                    <div className="field col-8">
                                        <label htmlFor={`product-${index}`}>#{index + 1} Product</label>
                                        <AutoComplete value={item.product} suggestions={filteredProducts}
                                            completeMethod={searchProduct} field="name"
                                            onChange={(e) => setInvoiceProductValue(e, index)} className="w-full"
                                            placeholder='Search Product'
                                        />
                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor={`qty-${index}`}>Qty</label>
                                        <InputText type={'number'} min='1' value={item.qty} id={`qty-${index}`} onChange={(e) => setInvoiceValues(e, index, 'qty')} className="w-full" />
                                    </div>
                                    <div className="field pl-2 flex align-items-end justify-content-end">
                                        {index !== 0 ?
                                            <Button icon="pi pi-trash" className="bg-none p-button-danger p-button-rounded p-button-outlined" onClick={() => removeField(index)} />
                                            : <div style={{ width: '40px' }}></div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="flex justify-content-end mt-0">
                        <Button type="button" icon="pi pi-plus" label="Add Item" className='p-button-success p-button-outlined p-button-rounded' onClick={addField} />
                    </div>

                    <Divider />
                    <div className='flex justify-content-end'>
                    <Button label={`${loading ? 'loading..' : 'Send Stock'}`} type="submit" disabled={loading} className="mt-0" />
                    </div>
                </form>
                <Toast ref={toast} />

            </Card>
            </div>
        </>
    )
}

export default StockTransfer