import React, { useState, useRef, useEffect } from 'react'
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';
import PreviewDialog from "./PreviewDialog"
import { Toast } from 'primereact/toast';
import axios from "../../lib/axios";
import { Helmet } from 'react-helmet';
import { numericOnly } from '../../lib/helper';


// purchase entry page
const Inventory = () => {

    // const [formData, setFormData] = useState([]);
    const [show_preview, setPreview] = useState(false);
    const [preview_array, setPreviewArray] = useState([]);
    const [cargoPrice, setCargoPrice] = useState(0);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [subTotal, setSubTotal] = useState(parseFloat(0).toFixed(2));
    const [loading, setLoading] = useState(false)
    const [currencies, setCurrencies] = useState([]);
    const [kwd, setKwd] = useState('');
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [invoiceTotal, setInvoiceTotal] = useState(0);

    const [invoiceItems, setInvoiceItems] = useState([
        {
            product: { name: '', id: '' },
            price: '',
            qty: 1,
            total: 0,
        }
    ]);

    useEffect(()=>{
        setInvoiceTotal(invoiceItems.reduce((sum, item) => sum + item.total, 0));
    },[invoiceItems]);
    useEffect(()=>{
        if(!isNaN(parseFloat(invoiceTotal))){
            formik.setFieldValue('invoice_amount', parseFloat(invoiceTotal))
        }
    },[invoiceTotal]);

    const toast = useRef(null);

    const getCurrencies = () => {
        axios.get(`api/currencies`)
            .then((res) => {
                let currencies = res.data.map(currency => {
                    return {
                        label: currency.name,
                        value: currency.kuwait_value,
                    }
                })
                setCurrencies(currencies);
            })
            .catch((error) => {

            })
    }

    const searchSupplier = (e) => {
        axios.get('api/suppliers', {
            params: {
                key: e.query
            }
        })
            .then(res => {
                setFilteredSuppliers(res.data);
            })
            .catch(error => {
                console.log(error);
            })
    }



    // bind the invoice details dynamic field
    const setInvoiceValues = (e, index, name) => {
        let newInvoices = [...invoiceItems];

        if (name === 'price') {
            if (numericOnly(e.target.value)) {
                newInvoices[index][name] = e.target.value
                newInvoices[index]['total'] = newInvoices[index]['qty'] * newInvoices[index]['price'];
            }
        } else {
            newInvoices[index][name] = e.target.value
            newInvoices[index]['total'] = newInvoices[index]['qty'] * newInvoices[index]['price'];
        }

        setInvoiceItems(newInvoices);
    }


    const setInvoiceProductValue = (e, index) => {
        let newInvoices = [...invoiceItems];

        if (typeof e.value === "object") {
            newInvoices[index]['product'] = {
                name: e.value.name,
                id: e.value.id,
                cost: e.value.cost,
            }
            // newInvoices[index]['price'] = e.value.attributes.cost;
            // newInvoices[index]['total'] = e.value.cost * newInvoices[index]['qty'];
        } else {

            newInvoices[index]['product']['name'] = e.value;
        }

        setInvoiceItems(newInvoices);
    }


    const formik = useFormik({
        initialValues: {
            supplier: '',
            invoice_id: '',
            invoice_amount: '',
            date: null,
            _type: '',
            remark: ''
        },
        validate: (data) => {
            let errors = {};

            if (selectedSupplier.id === undefined) {
                errors.supplier = 'Supplier is required.';
            }

            if (!data.invoice_id) {
                errors.invoice_id = 'Invoice ID is required.';
            }


            if (!data.invoice_amount) {
                errors.invoice_amount = 'Invoice amount is required';
            }

            if (!data.date) {
                errors.date = 'Invoice Date is required';
            }

            return errors;
        },
        onSubmit: (data, { resetForm }) => {
            if (!kwd) {
                toast.current.show({ severity: 'warn', summary: 'Currency is required', detail: 'Currency is required', life: 3000 });
                return;
            }

            if (invoiceItems.some((val) => val.product.id === '' ||  val.product.id === null)) {
                toast.current.show({ severity: 'warn', summary: 'Product required', detail: 'Pruduct is not selected', life: 3000 });
            }
            else if (invoiceItems.some((val) => val.product.name === '')) {
                toast.current.show({ severity: 'warn', summary: 'Product name required', detail: 'Pruduct name cannot be emty', life: 3000 });
            }
            else if (invoiceItems.some((val) => val.qty === '')) {
                toast.current.show({ severity: 'warn', summary: 'Product quantity required', detail: 'Pruduct quantity cannot be emty', life: 3000 });
            }
            else if (invoiceItems.some((val) => val.price === '')) {
                toast.current.show({ severity: 'warn', summary: 'Product price required', detail: 'Pruduct price cannot be emty', life: 3000 });
            }
            else {
                submitInventory(data, resetForm)
                setSelectedSupplier('');
            }
        }
    });

    const submitInventory = (data, resetForm) => {
        setLoading(true)
        let preview_array = invoiceItems.length > 0 ? addRatesForEachInvoices() : []
        let arr = []
        for (let i of preview_array) {
            let obj = {}
            obj.type = 'purchase-lines'
            obj.id = i.product.id
            obj.qty = i.qty
            obj.price = i._price
            obj.total = i._total
            arr.push(obj)
        }
        let { ...rest } = data
        rest.supplier = selectedSupplier.id;
        axios.post('api/purchases', {
            data: {
                type: "purchases",
                attributes: rest,
                relationships: {
                    purchaseLines: {
                        data: arr
                    }
                },
            },
        }).then((res) => {
            if (res === undefined) throw "error";
            resetForm()
            setLoading(false)
            setInvoiceItems([
                {
                    product: { name: '', id: '' },
                    price: '',
                    qty: 1,
                    total: 0,
                }
            ])
            setCargoPrice('')
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Inventory created successfully', life: 3000 });

        }).catch((err) => {
            console.log(err.response);
            setLoading(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
        })
    }


    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    const addField = () => {
        let newTodos = [...invoiceItems];
        newTodos.push({
            product: { name: '', id: '' },
            price: '',
            qty: 1,
            total: 0,
        });
        setInvoiceItems(newTodos);

    }

    const removeField = (index) => {
        let newTodos = [...invoiceItems];
        newTodos.splice(index, 1);
        setInvoiceItems(newTodos);
    }

    const types = [
        { label: 'Purchase', value: 'increment' },
        { label: 'Return', value: 'decrement' },
    ];


    const searchProduct = (event) => {
        axios
            .get('/api/product', {
                params: {
                    key: `%${event.query}%`,
                    inventory: 0,
                }
            }).then((res) => {
                setFilteredProducts(res.data.data);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }

    const previewInvoice = () => {

        if (!cargoPrice) {
            toast.current.show({ severity: 'warn', summary: 'Value required', detail: 'Cargo Price is required', life: 3000 });
            return
        }

        // // let item_data = []

        let item_data = addRatesForEachInvoices()
        setPreviewArray(item_data)
        setPreview(true)
    }

    const addRatesForEachInvoices = () => {
        let items = [...invoiceItems];
        let subTotal = items.reduce((sum, item) => sum + (item.price * item.qty), 0);
        console.log('subtotal', subTotal);

        setSubTotal(subTotal);

        items.forEach((val) => {
            let c_rate = calculateTotal(parseFloat(val.price), parseInt(val.qty), subTotal)
            console.log('cargo_rate', c_rate);
            val.cargo_rate = c_rate;
            val._price = parseFloat((val.price * kwd) + (val.cargo_rate / parseInt(val.qty))).toFixed(2);
            let c_total = (Number(val._price) * val.qty);
            val._total = parseFloat(c_total).toFixed(2);
        })
        // console.log('after',items);
        // console.log('after-og',invoiceItems);
        return items;
    }
    const calculateTotal = (price, qty, subTotal) => {
        let _cargoPrice = parseFloat(cargoPrice);
        console.log(_cargoPrice, price, qty, subTotal);
        let newPrice = (_cargoPrice * (price * qty)) / subTotal;
        return parseFloat(newPrice).toFixed(2)
    }

    const hidePreview = () => {
        setPreview(false)
    }

    const checkDisable = () => {
        return invoiceItems.every((val) => val.product.name !== '')
    }

    useEffect(() => {
        getCurrencies();
        return (() => {
            setCurrencies([]);
        })
    }, []);

    return (
        <>
            <div>
                <Helmet>
                    <title>Purchase</title>
                </Helmet>
            </div>

            <h2 className='mt-0 flex align-items-center mr-auto'>Inventory</h2>
            <Toast ref={toast} />
            <div className='card-box'>
                <Card>
                    <form onSubmit={formik.handleSubmit}>

                        <div className="formgrid grid">

                            <div className="field col">
                                <label htmlFor="supplier">Supplier</label>
                                <AutoComplete value={selectedSupplier} suggestions={filteredSuppliers}
                                    completeMethod={searchSupplier} field="name"
                                    onSelect={(e) => setSelectedSupplier(e.value)}
                                    onChange={(e) => setSelectedSupplier(e.value)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('supplier'), 'w-full': true })}
                                    placeholder='Search Supplier'
                                />
                                {/* <InputText id="supplier" name="supplier" className={classNames({ 'p-invalid': isFormFieldValid('supplier'), 'w-full': true })} value={formik.values.supplier} onChange={formik.handleChange} /> */}
                                {getFormErrorMessage('supplier')}
                            </div>

                            <div className="field col">
                                <label htmlFor="invoice-id">Invoice ID</label>
                                <InputText id="invoiceId" name="invoice_id" className={classNames({ 'p-invalid': isFormFieldValid('invoice_id'), 'w-full': true })} value={formik.values.invoice_id} onChange={formik.handleChange} />
                                {getFormErrorMessage('invoice_id')}
                            </div>

                            <div className="field col">
                                <label htmlFor="invoiceDate">invoiceDate</label>
                                <Calendar id="invoiceDate" name="date" className={classNames({ 'p-invalid': isFormFieldValid('date'), 'w-full': true })} value={formik.values.date} onChange={formik.handleChange} showIcon />
                                {getFormErrorMessage('date')}
                            </div>
                        </div>

                        <div className="formgrid grid">

                            <div className="field col">
                                <label htmlFor="cargo_price">Cargo Price (KWD)</label>
                                <InputText id="cargo_price" name="cargo_price" className='w-full' value={cargoPrice}
                                    onChange={(e) => {
                                        if (numericOnly(e.target.value)) {
                                            setCargoPrice(e.target.value)
                                        }
                                    }
                                    } />
                            </div>

                            <div className="field col">
                                <label htmlFor="invoice_amount">Invoice Amount</label>
                                <InputText id="invoice_amount" name="invoice_amount" className={classNames({ 'p-invalid': isFormFieldValid('invoice_amount'), 'w-full': true })} value={formik.values.invoice_amount} onChange={(e) => {
                                    if (numericOnly(e.target.value)) {
                                        formik.handleChange(e)
                                    }
                                }} readOnly />
                                {getFormErrorMessage('invoice_amount')}
                            </div>

                            <div className="field col">
                                <label htmlFor="currency">Currency</label>
                                <Dropdown value={kwd} name="currency" className={classNames({ 'p-invalid': isFormFieldValid('currency'), 'w-full': true })} options={currencies} onChange={(e) => setKwd(e.value)} placeholder="Select a Currency" />
                                {getFormErrorMessage('currency')}
                            </div>

                            {/* <div className="field col">
                            <label htmlFor="type">Type</label>
                            <Dropdown value={formik.values._type} name="_type" className={classNames({ 'p-invalid': isFormFieldValid('_type'), 'w-full': true })} options={types} onChange={formik.handleChange} placeholder="Select a Type" />
                            {getFormErrorMessage('_type')}
                        </div> */}

                        </div>
                        <div className="formgrid grid">

                            <div className="field col-12 px-0 mb-2">
                                <label htmlFor="remark">Remark</label>
                                <InputText id="remark" name="remark" className={classNames({ 'p-invalid': isFormFieldValid('remark'), 'w-full': true })} value={formik.values.remark} onChange={formik.handleChange} />
                                {getFormErrorMessage('remark')}
                            </div>

                        </div>

                        {/* <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="remark">Remark</label>
                            <InputText id="remark" className='w-full' name="remark" value={formik.values.remark} onChange={formik.handleChange} />
                        </div>
                    </div> */}
                        <Divider />

                        <div className='formgrid grid ml-1 mt-4'>

                            {invoiceItems.map((item, index) => {
                                return (
                                    <div className="formgrid grid" key={index}>
                                        <div className="field col-5">
                                            <label htmlFor={`product-${index}`}>#{index + 1} Product</label>
                                            <AutoComplete value={item.product.name} suggestions={filteredProducts}
                                                completeMethod={searchProduct} field="name"
                                                onChange={(e) => setInvoiceProductValue(e, index)} className="w-full"
                                                placeholder='Search Product'
                                            />
                                        </div>
                                        <div className="field col-2">
                                            <label htmlFor={`qty-${index}`}>Qty</label>
                                            <InputText type={'number'} min='1' value={item.qty} id={`qty-${index}`} onChange={(e) => setInvoiceValues(e, index, 'qty')} className="w-full" />
                                        </div>
                                        <div className="field col-2">
                                            <label htmlFor={`price-${index}`}>Price</label>
                                            <InputText min='1' value={item.price} id={`price-${index}`} onChange={(e) => setInvoiceValues(e, index, 'price')} className="w-full" />
                                        </div>
                                        <div className="field col-2">
                                            <label htmlFor={`total-${index}`}>Total</label>
                                            <InputText readOnly value={item.total} id={`price-${index}`} contentEditable={false} className="w-full" />
                                        </div>

                                        <div className="field flex align-items-end">
                                            {index !== 0 ?
                                                <Button icon="pi pi-trash" className="p-button-danger mt-0 p-button-rounded p-button-outlined bg-none" onClick={() => removeField(index)} />
                                                : <div style={{ width: '40px' }}></div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="flex justify-content-end mt-2">
                            <Button type="button" icon="pi pi-plus" label="Add Item" className='p-button-success p-button-outlined p-button-rounded' onClick={addField} />

                        </div>

                        <Divider />
                        <div className='flex justify-content-end'>
                            <Button type="button" label="Preview" className="mr-4 p-button-help p-button-rounded"
                                onClick={previewInvoice}
                                disabled={!checkDisable()}
                            />
                            <Button disabled={loading} label="Add Inventory" type="submit" className="mt-0" loading={loading} />
                        </div>
                    </form>
                </Card>
            </div>

            <PreviewDialog isOpen={show_preview} onHide={hidePreview} invoiceItems={preview_array} />

        </>
    )
}

export default Inventory;
