import React, { useState, memo } from 'react'
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import axios from 'axios';
import { classNames } from 'primereact/utils';


// create shop popup page
function ShopDialog({ onHide, isOpen, updateFlag, shopdata }) {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      phone: (updateFlag && shopdata) ? shopdata.phone : '',
      name: (updateFlag && shopdata) ? shopdata.name : '',
      address: (updateFlag && shopdata) ? shopdata.address : '',
    },
    validate: (data) => {
      let errors = {};

      if (!data.address) {
        errors.address = 'Address is required.';
      }
      if (!data.phone) {
        errors.phone = 'Phone is required.';
      }
      if (!data.name) {
        errors.name = 'Name is required.';
      }


      return errors;
    },
    onSubmit: (data) => {
      setLoading(true);
      if (!updateFlag) {
        axios.post('/api/shop ', {
          data: {
            type: 'shop',
            attributes: data
          }
        })
          .then((res) => {
            setLoading(false)
            onHide('Updated');
          })
          .catch(error => {
            setLoading(false);
            onHide('Error');
            // if (error) {
            //   if (error.response.status === 400) {
            //     let err = Object.values(error?.response?.data)
            //     setCreateCustomerErrors(err)
            //   }
            // } else {
            //   console.log(error)
            // }
          });
      }
      else {
        axios.patch(`/api/shop/${shopdata.id}`, {
          data: {
            type: "shops",
            id: shopdata.id,
            attributes: {
              name: data.name,
              address: data.address,
              phone: data.phone
            }
          }
        })
          .then(res => {
            setLoading(false)
            onHide('Updated');
          })
          .catch(error => {
            console.log('error : ', error);
            setLoading(false)
            onHide('Error');
          })
      }
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <>
      <Dialog header={updateFlag ? 'Update Branch' : 'Add Branch'} visible={isOpen} style={{ width: '450px' }} modal
        className="p-fluid" onHide={onHide}>
        <form onSubmit={formik.handleSubmit} className="p-fluid">

          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText id="name" name="name" value={formik.values.name} autoFocus onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
            {getFormErrorMessage('name')}
          </div>
          <div className="field">
            <label htmlFor="address">Address</label>
            <InputTextarea id="address" name="address" value={formik.values.address} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('address') })} rows={3} cols={20} />
            {getFormErrorMessage('address')}
          </div>
          <div className="field">
            <label htmlFor="phone">Phone</label>
            <InputText type={"number"} id="phone" name="phone" value={formik.values.phone} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('phone') })} />
            {getFormErrorMessage('phone')}
          </div>

          <div className='flex justify-content-end'>
          <Button type="submit" label={`${loading ? 'loading...' : updateFlag ? 'Update Branch' : 'Add Branch'}`} disabled={loading} />
          </div>
          
        </form>
      </Dialog>
    </>
  )
}

export default memo(ShopDialog)