import { useSelector } from 'react-redux';

const menu = [
    {
        label: 'Home',
        items: [{
            label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'
        }]
    },
    
    {
        label: 'Stock Transfer', icon: 'pi pi-fw pi-sitemap',
        items: [
            { label: 'Stock Transfer Details ', icon: 'pi pi-fw pi-sort-amount-up-alt', to: '/stock-transfer-details' },

        ]
    },
    
    {
        label: 'Customers',
        items: [
            { label: 'Customer', icon: 'pi pi-fw pi-user', to: '/customers' },
            { label: 'Customer Ledger', icon: 'pi pi-fw pi-search', to: '/customer-ledger' }
        ]
    },

    {
        label: 'Products', icon: 'pi pi-fw pi-sitemap',
        items: [
            { label: 'Manage Products', icon: 'pi pi-fw pi-check-square', to: '/products/create' },
        ]
    },

    {
        label: 'Damage',
        items: [
            { label: 'Damage Entries', icon: 'pi pi-fw pi-user', to: '/damage-entries' },
        ]
    },

    {
        label: 'Branches',
        items: [{
            label: 'Branches', icon: 'pi pi-fw pi-building', to: '/shops'
        }]
    },

    {
        label: 'Shop User',
        items: [{
            label: 'Shop User', icon: 'pi pi-fw pi-user', to: '/users'
        }]
    },
   

    // {
    //     label: 'Stock Transfer', icon: 'pi pi-fw pi-sitemap',
    //     items: [
    //         { label: 'Stock Transfer', icon: 'pi pi-fw pi-sort-amount-up-alt', to: '/stock-transfer' },
    //         { label: 'Stock Transfer Details ', icon: 'pi pi-fw pi-sort-amount-up-alt', to: '/stock-transfer-details' },

    //     ]
    // },
    

    // {
    //     label: 'Get Started',
    //     items: [
    //         { label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => { window.location = "#/documentation" } },
    //         { label: 'View Source', icon: 'pi pi-fw pi-search', command: () => { window.location = "https://github.com/primefaces/sakai-react" } }
    //     ]
    // },
    // {
    //     label: 'Purchase Management', icon: 'pi pi-fw pi-sitemap',
    //     items: [
    //         { label: 'Purchase Details', icon: 'pi pi-fw pi-box', to: '/purchase-details' }
    //     ]
    // },


    {
        label: 'Currencies',
        items: [
            { label: 'Currencies', icon: 'pi pi-dollar', to: '/currencies' },
        ]
    },
    // {
    //     label: 'Return',
    //     items: [
    //         { label: 'Return', icon: 'pi pi-shopping-cart', to: '/return' },
    //     ]
    // },
 
    {
        label: 'Reports',
        items: [
            { label: 'Customer Ledger', icon: 'pi pi-fw pi-search', to: '/customer-ledger' },
            { label: 'Stock Transfer Details ', icon: 'pi pi-fw pi-sort-amount-up-alt', to: '/stock-transfer-details' },
            { label: 'Damage Entries', icon: 'pi pi-fw pi-user', to: '/damage-entries' },
        ]
    }
];

export { menu };