import axios from '../../lib/axios';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Dialog } from 'primereact/dialog';



// customer ledger page
export default function customerLedger() {
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [todate, setToDate] = useState(null);
    const [fromdate, setFromDate] = useState(null);
    const [ledger, setLedger] = useState();
    const [openingBalance, setOpeningBalance] = useState();
    const [closingBalance, setClosingBalance] = useState();
    // const [showDialog, setShowDialog] = useState(false);
    // const [orderLines, setOrderLines] = useState([]);

    useEffect(() => {
        const att = document.createAttribute("required");
        document.getElementsByTagName("input")[0].setAttributeNode(att)
    }, []);


    const searchCustomer = (event) => {
        let _filteredCustomers;
        axios.get(`/api/customer?key=${event.query}`)
            .then(res => {
                _filteredCustomers = res.data.filter((e) => {
                    return e.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
                setCustomer(_filteredCustomers);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }


    function submitEvent(e) {
        e.preventDefault();
        setLoading(true);
        let from_date = moment(fromdate).toISOString(true);
        let to_date = moment(todate).toISOString(true);
        axios.post(`api/customer/ledger-report`, {
            data: {
                type: "customer",
                attributes: {
                    customer_id: selectedCustomer.id,
                    from_date: from_date,
                    to_date: to_date
                }
            }
        })
            .then(res => {
                setLedger(res.data.transactions);
                setLoading(false);
                setOpeningBalance(res.data.openingBalance);
                setClosingBalance(res.data?.transactions[res.data?.transactions?.length - 1]?.balance);
            })
    }

    const header = () => {
        return (
            <div className="flex justify-content-between">
                <div>Opening Balance : {openingBalance}</div>
                <div>Closing Balance: {closingBalance}</div>
            </div>
        )
    }

    // const itemDetails = rowData => {
    //     return rowData.order_id ? <Button icon="pi pi-eye" onClick={() => showItems(rowData.order_id)} /> : `Paid against${rowData.item}`;
    // }

    // const showItems = order_id => {
    //     axios.get('api/order-lines', {
    //         params: {
    //             order_id: order_id
    //         }
    //     }).then((res) => {
    //         setOrderLines(res.data);
    //         setShowDialog(true);
    //     }).catch(error => { })
    // }

    // const totalPrice = rowData => rowData.qty * rowData.sold_price;

    return (
        <>
            <div>
                <Helmet>
                    <title>Customer Ledger</title>
                </Helmet>
            </div>
            <div className='flex align-items-center justify-between'>
                <h2 className='mt-0 flex align-items-center'>Customer Ledger</h2>
            </div>
            <form onSubmit={submitEvent} >
                <div className="col-12 p-0">
                    <div className="card p-fluid pb-0">

                        <div className="formgrid grid">

                            <div className="field col">
                                <label htmlFor="name1">Select Customer</label>
                                <AutoComplete className='w-full' placeholder="Select customer" inputId='select_customer' name='customer' value={selectedCustomer} suggestions={customer} completeMethod={searchCustomer} field="name" onChange={(e) => setSelectedCustomer(e.value)} />
                            </div>

                            <div className="field col">
                                <label htmlFor="from Date">From Date</label>
                                <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='fromdate' value={fromdate} onChange={(e) => setFromDate(e.value)} showIcon required />
                            </div>

                            <div className="field col">
                                <label htmlFor="To Date">To Date</label>
                                <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='todate' value={todate} onChange={(e) => setToDate(e.value)} showIcon required />
                            </div>

                            <div className="field flex align-items-end pl-2">
                                <Button label="Submit" type="submit" aria-label="Submit" />
                            </div>
                        </div>

                        <br />

                        <DataTable className='border-bottom-rounded' value={ledger} header={header} responsiveLayout="scroll" loading={loading} emptyMessage="No Customer Ledger found." >
                            <Column field="date" header="Date"></Column>
                            <Column field="credit" header="Credit"></Column>
                            <Column field="debit" header="Debit"></Column>
                            <Column field="balance" header="Balance"></Column>
                            {/* <Column body={itemDetails} header="Items"></Column> */}
                            <Column field="remarks" header="Remarks"></Column>
                        </DataTable>
                    </div>


                </div>
            </form>

            {/* <Dialog visible={showDialog} onHide={() => setShowDialog(false)}>
                <DataTable value={orderLines} >
                    <Column field="product.name" header="Item"></Column>
                    <Column field="qty" header="Qty"></Column>
                    <Column field="sold_price" header="Price"></Column>
                    <Column body={totalPrice} header="Total"></Column>
                </DataTable>
            </Dialog> */}
        </>

    )
}