import React, { useEffect, useState, useRef, memo } from 'react'
import { useForm } from '../../../lib/useForm';
import { FastField, useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
// import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import axios from 'axios';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';


// create product popup
const CreateProductDialog = ({ isOpen, onHide, product_data, edit }) => {
    const [showMessage, setShowMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const toasts = useRef(null);
    let opType = '';

    if (edit === true) {
        opType = 'Update Product'
    } else {
        opType = 'Create New Product'
    }
    useEffect(() => {
        // console.log(product_data);
    }, [formData]);


    const formik = useFormik({
        initialValues: {
            id: edit ? product_data?.id : '',
            name: edit ? product_data?.name : '',
            description: edit ? product_data?.description : '',
            sku: edit ? product_data?.sku : '',
            cost: edit ? product_data?.cost : '',
            brand: edit ? product_data?.brand : '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name is required.';
            }
            if(data.name?.length > 40){
                errors.name = 'Name max length is 40';
            }

            if (!data.description) {
                errors.description = 'Description is required.';
            }

            if(data.description?.length > 255){
                errors.description = 'Description max length is 255';
            }

            // if (!data.sku) {
            //     errors.sku = 'Sku is required';
            // }

            if (!data.cost) {
                errors.cost = 'Cost is required';
            }

            if (data.cost.length > 8) {
                errors.cost = 'Cost max length 8 digits';
            }

            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            setShowMessage(true);
            formik.resetForm();
            if (edit === false) {
                storeProduct(data);
            }
            else {
                editProduct(data);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const storeProduct = (data) => {
        setLoading(true)
        axios
            .post('/api/products', {
                data: {
                    type: 'products',
                    attributes: {
                        ...data,
                        ['cost']: parseFloat(data.cost)
                    }
                }
            })
            .then((res) => {
                setLoading(false)
                onHide('Success');
            })
            .catch(error => {
                setLoading(false)
                console.log(error.response.data.errors);
                onHide('Error');
            });
    }

    const editProduct = (newdata) => {
        setLoading(true);

        axios.patch(`/api/products/${newdata.id}`, {
            data: {
                type: "products",
                id: newdata.id,
                attributes: {
                    name: newdata.name,
                    description: newdata.description,
                    sku: newdata.sku,
                    cost: newdata.cost,
                    brand: newdata.brand
                }
            }
        }).then((res) => {
            setLoading(false);
            onHide('Success');
        }).catch(error => {
            setLoading(false);
            onHide('Error');
        });
    }

    return (
        <div>
            <Toast ref={toasts} />

            <Dialog header="Create Product" visible={isOpen} style={{ width: '450px' }} modal
                className="p-fluid" onHide={onHide}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field">
                        <label htmlFor="name">Name</label>
                        <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                        {getFormErrorMessage('name')}
                    </div>
                    <div className="field">
                        <label htmlFor="description">Description</label>
                        <InputTextarea id="description" name="description" value={formik.values.description} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('description') })} rows={3} cols={20} />
                        {getFormErrorMessage('description')}
                    </div>
                    <div className="field">
                        <label htmlFor="sku">SKU</label>
                        <InputText id="sku" name="sku" value={formik.values.sku} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('sku') })} />
                        {getFormErrorMessage('sku')}
                    </div>
                    <div className="field">
                        <label htmlFor="brand">Brand</label>
                        <InputText id="brand" name="brand" value={formik.values.brand} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('brand') })} />
                        {getFormErrorMessage('brand')}
                    </div>
                    <div className="field">
                        <label htmlFor="cost">Cost</label>
                        <InputText id="cost" name="cost" value={formik.values.cost} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('cost') })} />
                        {getFormErrorMessage('cost')}
                    </div>
                    {/* <div className="field">
                <label htmlFor="cost">Category</label>
                <Dropdown id="category" value={newProduct.category} options={categories} onChange={(e) => setNewProduct('category', e.value)} placeholder="Select a Category" />
            </div> */}
                    <div className='flex justify-content-end'>
                        <Button type="submit" label={`${loading ? 'loading..' : opType} `} disabled={loading} />
                    </div>
                </form>
            </Dialog>
        </div>
    )
}

export default memo(CreateProductDialog);