import React, { useState, useRef } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import axios from '../../lib/axios';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Toast } from 'primereact/toast';
import OrderLinesView from './components/OrderLinesView';


// consolidate orders page
const Consolidate = () => {

    const [loading, setLoading] = useState(false);
    const [consolidateLoading, setConsolidateLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [orders, setOrders] = useState([]);
    const [date, setDate] = useState('');
    const [customer, setCustomer] = useState([]);
    const toasts = useRef(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [openOrderLines, setOpenOrderLines] = useState(false);
    const [orderLines, setOrderLines] = useState([]);


    const searchCustomer = (event) => {
        let _filteredCustomers;
        axios.get(`/api/customer?key=${event.query}`)
            .then(res => {
                _filteredCustomers = res.data.filter((e) => {
                    return e.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
                setCustomer(_filteredCustomers);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }

    const getOrders = () => {

        if (!selectedCustomer?.id) {
            toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'Customer required' });
            return;
        }

        if (!date) {
            toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'Date required' });
            return;
        }


        setLoading(true);
        axios.get('api/orders/consolidate', {
            params: {
                customer_id: selectedCustomer.id,
                date: moment(date).toISOString(true),
            }
        })
            .then(res => {
                setOrders(res.data.data);
            }).catch(error => {
                toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong' });
            }).finally(() => {
                setLoading(false);
            })
    }

    const onSelectionChange = (event) => {
        const value = event.value;
        setSelectedOrders(value);
        setSelectAll(value.length === totalRecords);
    }

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            setSelectAll(true);
            setSelectedOrders(orders);
        }
        else {
            setSelectAll(false);
            setSelectedOrders([]);
        }
    }

    const dataTableHeader = () => {
        return (
            <Button type="button" label="Consolidate" loading={consolidateLoading} onClick={consolidate} disabled={selectedOrders.length < 2} />
        )
    }

    const viewLinesBtn = (rowData) => <Button label="View Items" onClick={() => getOrderLines(rowData.id)} />

    const consolidate = () => {
        if (selectedOrders.length > 1) {

            setConsolidateLoading(true);
            axios.post('api/consolidate-order', {
                order_ids: selectedOrders.map(order => {
                    return order.id;
                })
            }).then(res => {
                toasts.current.show({ severity: 'success', summary: 'Success Message', detail: res.data?.message });
                getOrders();
                setSelectAll(false);
                setSelectedOrders([]);
            }).catch(error => {
                toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong' });
            }).finally(() => setConsolidateLoading(false))

        } else {
            toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'Select two or more orders' });
        }
    }

    const getOrderLines = (order_id) => {
        axios.get(`api/order/order-lines/${order_id}`)
            .then(res => {
                setOrderLines(res.data.data);
                setOpenOrderLines(true);
            }).catch(error => { });
    }

    return (
        <>
            <Helmet>
                <title>Consolidate | Order</title>
            </Helmet>

            <div className='card-outer'>
            <h2 className='mt-0 flex align-items-center'>Consolidate Orders</h2>
            <Card className='no-pb'>
                
                <div className="formgrid grid">

                    <div className="field col">
                        <label htmlFor="select_customer">Select Customer</label><br />
                        <AutoComplete className='w-full' placeholder="Select customer" inputId='select_customer' name='customer' value={selectedCustomer} suggestions={customer} completeMethod={searchCustomer} field="name" onChange={(e) => setSelectedCustomer(e.value)} />
                    </div>

                    <div className="field col">
                        <label htmlFor="date">Date</label><br />
                        <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name="date" value={date} onChange={(e) => setDate(e.value)} showIcon required />
                    </div>

                    <div className="field flex align-items-end pl-2">
                        <Button label="Submit" className="flex justify-content-end" type="button" aria-label="Submit" onClick={getOrders} />
                    </div>

                </div>
                <DataTable className='table-header-right br-p-datatable-wrapper' value={orders} lazy responsiveLayout="scroll" loading={loading}
                    selection={selectedOrders} onSelectionChange={onSelectionChange} header={dataTableHeader}
                    selectAll={selectAll} onSelectAllChange={onSelectAllChange} emptyMessage="No Orders" >
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="created_at" header="Date"></Column>
                    <Column field="order_number" header="Order Number"></Column>
                    <Column field="total" header="Total"></Column>
                    <Column body={viewLinesBtn} header="Action"></Column>
                </DataTable>

                <Toast ref={toasts} />
            </Card>
            </div>
            <OrderLinesView visible={openOrderLines} orderLines={orderLines} onHide={() => setOpenOrderLines(false)}/>

            {/* <Dialog header="Order Items" visible={openOrderLines} style={{ width: '50vw' }} onHide={() => setOpenOrderLines(false)}>
                <DataTable value={orderLines} responsiveLayout="scroll">
                    <Column field="item" header="Item"></Column>
                    <Column field="description" header="Description"></Column>
                    <Column field="qty" header="Quantity"></Column> 
                    <Column field="sold_price" header="Price"></Column>
                </DataTable>
            </Dialog> */}
        </>
    );
}

export default Consolidate;
