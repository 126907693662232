import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


// manage product details
function ManageProductDetails({ isOpen, onHide, product_data }) {
    const [loading, setLoading] = useState(false);
    const [productDetails, setProductDetails] = useState();

    useEffect(() => {
        setLoading(true);
        axios.get(`/api/purchases/items/${product_data.id}`)
            .then(res => {
                setProductDetails(res.data);
                setLoading(false);
            });
        
            return(()=>{
                setProductDetails([]);
            })
    }, [])


    return (
        <>
            <Dialog header={`Details`} visible={isOpen} style={{ width: '450px' }} modal className="p-fluid" onHide={onHide} >
                <DataTable value={productDetails} responsiveLayout="scroll" emptyMessage="No transactions found." loading={loading}  >
                    <Column field="price" header="Price"></Column>
                    <Column field="product" header="Product"></Column>
                    <Column field="qty" header="Quantity"></Column>
                </DataTable>
            </Dialog>
        </>
    )
}

export default ManageProductDetails