import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import axios from '../../lib/axios';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import { TabMenu } from 'primereact/tabmenu';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';


// list damage page
const Damage = () => {

    const [damageEntries, setDamageEntries] = useState([]);
    const [selectedEntries, setSelectedEntries] = useState([]);
    const toast = useRef(null);
    const [searchKey, setSearchKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSendToVendor, setIsSendToVendor] = useState(false);
    const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);


    useEffect(() => {
        getDamageEntries(isSendToVendor);
        return (() => {
            setDamageEntries([]);
            setDamageEntries([]);
        })
    }, [searchKey]);

    const getDamageEntries = (sendToVendor) => {
        setLoading(true);
        setSelectedEntries([]);
        axios.get('api/damage-entries', {
            params: {
                sendToVendor: sendToVendor,
                searchKey: searchKey
            }
        })
            .then(res => {
                setDamageEntries(res.data)
            }).catch(err => { })
            .finally(() => setLoading(false))
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            let _damageEntries = damageEntries.map(entry => {
                return {
                    item: entry.order_line?.product?.name,
                    qty: entry.order_line?.qty,
                    branch: entry.shop?.name,
                }
            })
            const worksheet = xlsx.utils.json_to_sheet(_damageEntries);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'damage entries');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    const confirm = (acceptFunc) => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptFunc(),
            reject: () => setVisibleConfirmDialog(false)
        });
    }




    const header = () => (
        <div className="flex justify-content-between align-items-center">
            <div className="flex align-items-center export-buttons">
                <InputText value={searchKey} onChange={e => setSearchKey(e.target.value)} placeholder="Search Items" />
            </div>
            <div>
                <Button label="Export excel" type="button" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" />
                {activeIndex === 0 ?
                    <Button label="Send to vendor" disabled={selectedEntries.length == 0} onClick={() => confirm(sendToVendor)} />
                    : <>
                        <Button label="Add to stock" className="mr-2" disabled={selectedEntries.length == 0} onClick={() => confirm(addToStock)} />
                        <Button label="Add to dead stock" disabled={selectedEntries.length == 0} onClick={() => confirm(addToDeadStock)} />
                    </>}
            </div>
        </div>

    )

    const sendToVendor = () => {
        axios.put('api/damage-entries/send-to-ventor', {
            damaged_ids: selectedEntries.map(entry => {
                return entry.id;
            })
        }).then(res => {
            getDamageEntries(false);
            toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully completed' })
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Somthing went wrong' })
        })
    }
    const addToStock = () => {
        axios.post('api/damage-entries/add-to-stock', {
            damaged_ids: selectedEntries.map(entry => {
                return entry.id;
            })
        }).then(res => {
            setIsSendToVendor(1);
            getDamageEntries(1);
            toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully completed' })
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Somthing went wrong' })
        })
    }
    const addToDeadStock = () => {
        axios.post('api/damage-entries/add-to-dead-stock', {
            damaged_ids: selectedEntries.map(entry => {
                return entry.id;
            })
        }).then(res => {
            setIsSendToVendor(1);
            getDamageEntries(1);
            toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully completed' })
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Somthing went wrong' })
        })
    }

    const returnedDateBody = rowData => moment(rowData?.return_order_line?.created_at).format('DD-mm-yyyy');

    const items = [
        { label: 'Damage Entries', index: 0 },
        { label: 'Send To Vendors', index: 1 }
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <>
            <Helmet>
                <title>Damage Entries</title>
            </Helmet>
            <ConfirmDialog />
            <h2 className='mt-0 flex align-items-center mr-auto'>Damage Entries</h2>
            <div className='card-box'>
                <Card className='no-px-2'>
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => {
                        setSelectedEntries([]);
                        setSearchKey('');
                        setIsSendToVendor(e.value.index);
                        getDamageEntries(e.value.index);
                        setActiveIndex(e.value.index);
                    }} />
                    <DataTable className='datatable-rounded tr-p-datatable-header' value={damageEntries} selection={selectedEntries}
                        onSelectionChange={e => setSelectedEntries(e.value)}
                        loading={loading}
                        dataKey="id" responsiveLayout="scroll" header={header}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                        <Column body={returnedDateBody} header="Returned Date" />
                        <Column field="order_line.product.name" header="Item" />
                        <Column field="order_line.qty" header="Qty" />
                        <Column field="shop.name" header="Branch" />
                    </DataTable>
                </Card>
            </div>

            <Toast ref={toast} />
        </>
    );
}

export default Damage;
