import React from 'react';
import Dashboard from "./dashboard/Dashboard";
import { HashRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './dashboard/pages/reduxComponents/Store/Store';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';

export default function ReactApp() {
    return (
        <HashRouter  >
            <Provider store={store}>
                <Dashboard />
            </Provider>
        </HashRouter>
    );
}

