import React, { useState, useEffect, memo } from "react";
import ErrorList from "../../../components/ErrorList";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import PropTypes from "prop-types";
import axios from "../../../lib/axios";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';


// create customer popup
function CreateCustomerDialog(props) {
    const [shops, setShops] = useState();
    const [shopSelected, setShopSelected] = useState();

    const [loading, setLoading] = useState(false)
    const [shopId, setShopId] = useState(props?.customerdata?.shop_id);
    const [collectionAgents, setCollectionAgents] = useState();

    const customer_id = props?.customerdata?.id
    const LoadCollectionAgent = async (data = null) => {

        let id = (data) ? data.id : shopId

        if (props.flag) {
            await axios.get(`api/collection-agents/shop/${id}`)
                .then(res => {
                    setCollectionAgents(res.data?.map(agent => ({ value: agent.id, label: agent.name })))
                    return res.data;
                })
        } else {
            await axios.get(`api/collection-agents/shop/${data.id}`)
                .then(res => {
                    setCollectionAgents(res.data?.map(agent => ({ value: agent.id, label: agent.name })))
                    return res.data;
                })
        }
    }

    useEffect(() => {
        if (props.flag) {
            LoadCollectionAgent();
        }

        return (() => {
            setCollectionAgents([]);
            setCollectionAgents([]);
            setShops([]);
        });

    }, [customer_id])

    const LoadShops = () => {
        axios.get('api/shop')
            .then(e => {
                setShops(e.data)
            })
    }

    useEffect(() => {
        if (!props.flag) {
            axios.get(`/api/collection-agents`)
                .then(res => {
                    setCollectionAgents(res.data?.map(agent => ({ value: agent.id, label: agent.name })))
                });
        };
        LoadShops()
    }, [])


    const [newCustomer, setNewCustomer] = useState({
        name: '',
        address: '',
        customer_agent_id: null,
        is_business: false,
        phone: '',
        opening_balance: 0,
        email: '',
        remark: ''
    });

    const [createCustomerErrors, setCreateCustomerErrors] = useState([]);

    useEffect(() => {
        setCreateCustomerErrors([])
        setNewCustomer({
            shop: (props?.customerdata && props.flag === true) ? (props?.isSuperAdmin) ? props?.customerdata?.shop?.id : props?.customerdata?.shop_id : '',
            name: (props?.customerdata && props.flag === true) ? props?.customerdata?.name : '',
            address: (props?.customerdata && props.flag === true) ? props?.customerdata?.address : '',
            customer_agent_id: (props?.customerdata && props.flag === true) ? props?.customerdata?.id : 0,
            is_business: (props?.customerdata && props.flag === true) ? props?.customerdata?.is_business : false,
            phone: (props?.customerdata && props.flag === true) ? props?.customerdata?.phone : '',
            email: (props?.customerdata && props.flag === true) ? props?.customerdata?.email : '',
            remark: (props?.customerdata && props.flag === true) ? props?.customerdata?.remark : '',
            customer_agent_id: (props?.customerdata && props.flag === true) ? props?.customerdata?.agent_customer?.collection_agent_id : '',
            opening_balance: (props?.customerdata && props.flag === true) ? props?.customerdata?.opening_balance : 0
        })
    }, [])

    const onInputChange = (e, name) => {
        const val = e;
        const re = /^[0-9\b]+$/;
        let _customer = { ...newCustomer };
        if (name === 'phone') {
            if ((re.test(val) && val.length <= 10) || val == '') {
                _customer[`${name}`] = val;
            }
        } else {
            _customer[`${name}`] = val;
        }

        setNewCustomer(_customer);
    }

    const createCustomer = (e) => {
        setLoading(true);

        if (props.flag === true) {
            axios.put(`/api/customer/${props.customerdata.id}`, {
                data: {
                    type: "customer",
                    attributes: {
                        ...newCustomer
                    }
                }
            }).then(res => {
                setLoading(false);
                props.onHide('Success')
                props.onUpdateCustomer(res.data);
            }).catch(error => {
                props.onHide('Error')
            })
        }
        else {
            axios
                .post('/api/customer', {
                    data: {
                        type: 'customers',
                        attributes: {
                            ...newCustomer
                        },
                    }
                })
                .then((res) => {
                    setLoading(false);
                    props.onHide('Success')
                    props.onCreatedCustomer(res.data);
                })
                .catch(error => {
                    setLoading(false);
                    props.onHide('Error')
                    setCreateCustomerErrors(error.response.data.errors);
                });
        }
    };

    const OnShopSelect = (data) => {
        LoadCollectionAgent(data);

    }

    return (
        <>
            <Dialog visible={props.visible} style={{ width: '450px' }} header="Enter Customer Details" modal
                className="p-fluid"
                onHide={props.onHide} loading={(loading)?('true'):('false')}>
                <ErrorList errors={createCustomerErrors} />

                <form className="p-fluid" onSubmit={createCustomer}>
                    {props.isSuperAdmin &&
                        <>
                            <div className="field">
                                <label htmlFor="branch">Branch</label>
                                <Dropdown id="shops" value={shopSelected} options={shops} optionLabel="name" placeholder={(props.flag) ? props.customerdata.shop.name : ''} onChange={(e) => { setShopSelected(e.value); OnShopSelect(e.value); onInputChange(e.target.value.id, 'shop') }} required />
                            </div>
                        </>
                    }

                    <div className="field">
                        <label htmlFor="collection_agent">Collection agent</label>
                        <Dropdown id="selectedAgent" value={newCustomer?.customer_agent_id} options={collectionAgents} onChange={(e) => { onInputChange(e.value, 'customer_agent_id') }} placeholder="Select an agent" required />
                    </div>

                    <div className="field">
                        <label htmlFor="name">Name</label>
                        <InputText id="name" value={newCustomer.name} onChange={(e) => onInputChange(e.target.value, 'name')} required />
                    </div>

                    <div className="field">
                        <label htmlFor="address">Address</label>
                        <InputTextarea id="address" value={newCustomer.address}
                            onChange={(e) => onInputChange(e.target.value, 'address')} rows={3} cols={20} required />
                    </div>
                    <div className="field">
                        <label htmlFor="email">Email</label>
                        <InputText type="email" id="email" value={newCustomer.email} onChange={(e) => onInputChange(e.target.value, 'email')} required />
                    </div>
                    <div className="field">
                        <label htmlFor="phone">Phone</label>
                        <InputText id="phone" value={newCustomer.phone} onChange={(e) => onInputChange(e.target.value, 'phone')} required />
                    </div>
                    <div className="field">
                        <label htmlFor="opening_balance">Opening Balance</label>
                        <InputNumber id="opening_balance" min={0} value={newCustomer.opening_balance} onChange={(e) =>  onInputChange(e.value, 'opening_balance')} minFractionDigits={2} />
                    </div>
                    <div className="field-checkbox">
                        <Checkbox id="isBusiness" checked={newCustomer.is_business} onChange={e => setNewCustomer({ ...newCustomer, is_business: e.checked })} />
                        <label htmlFor="isBusiness" className="mr-2">Is Business</label>
                    </div>
                    <>
                        <div className="flex justify-content-end">
                            <Button label={`${loading ? "Loading..." : props.flag ? "Update Customer" : "Create New Customer"}`} disabled={loading} icon="pi pi-plus" />
                        </div>
                    </>

                </form>
            </Dialog>
        </>
    )
}

CreateCustomerDialog.propTypes = {
    visible: PropTypes.bool,
    onCreatedCustomer: PropTypes.func,
    onHide: PropTypes.func,
}

export default memo(CreateCustomerDialog);