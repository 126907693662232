// import { get } from 'lodash';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import axios from "../../lib/axios";
import ReactToPrint from 'react-to-print';
// import { ComponentToPrint } from "./ComponentToPrint";
import ComponentToPrint from "./ComponentToPrint"
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { InputText } from 'primereact/inputtext';
import { Helmet } from 'react-helmet';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { useHistory } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { AutoComplete } from 'primereact/autocomplete';
import _, { capitalize } from 'lodash';


// list orders page
const Order = () => {

    useEffect(() => {
        getOrders();
        getDeliveryAgents();

        return (() => {
            setDeliveryAgents({});
            setOrderData({});
        });

    }, []);

    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [orders, setOrders] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toast = useRef(null);

    const [deliveryAgents, setDeliveryAgents] = useState({});
    const [selectedDeliveryAgent, setSelectedDeliveryAgent] = useState('');
    const [orderData, setOrderData] = useState({});

    const [showDialog, setShowDialog] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [orderLines, setOrderLines] = useState([]);
    const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [fromDate, setFormDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [filteredCustomers, setFilteredCustomers] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios.get(`/api/orders`, {
            params: {
                key: searchKey
            }
        })
            .then(res => {

                const { data, meta } = res.data;
                setTotalRecords(meta.total);
                let _orders = data.map((order, i) => {
                    return {
                        ...order,
                        ['serial_number']: i + meta.from
                    }
                })
                setOrders(_orders);

                // console.log(res.data.meta.total);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
        setLoading(false);
    }, [searchKey]);


    const filterOrder = () => {
        // if (!selectedCustomer?.id) {
        //     toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Customer is required' });
        //     return;
        // }

        if (!fromDate) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'from date is required' });
            return;
        }
        if (!toDate) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'to date is required' });
            return;
        }

        setLoading(true);
        axios.get(`/api/orders`, {
            params: {
                customer_id: selectedCustomer?.id,
                fromDate: moment(fromDate).toISOString(true),
                toDate: moment(toDate).toISOString(true),
            }
        })
            .then(res => {
                const { data, meta } = res.data;
                setTotalRecords(meta.total);
                let _orders = data.map((order, i) => {
                    return {
                        ...order,
                        ['serial_number']: i + meta.from
                    }
                })
                setOrders(_orders);

                // console.log(res.data.meta.total);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            }).finally(() => setLoading(false));

    }


    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            'attributes.name': { value: '', matchMode: 'contains' },
            'attributes.description': { value: '', matchMode: 'contains' },
            'attributes.cost': { value: '', matchMode: 'contains' },
        }
    });
    const [generatedInvoice, setGeneratedInvoice] = useState(null)

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;

    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">Orders</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }

    const showItems = order_id => {
        axios.get('api/order-lines', {
            params: {
                order_id: order_id
            }
        }).then((res) => {
            setOrderLines(res.data);
            setShowDialog(true);
        }).catch(error => { })
    }

    const showItemsBtn = rowData => <a role="button" className='btn-view' onClick={() => showItems(rowData.id)}>{rowData?.order_number}</a>
    const totalPrice = rowData => parseFloat(rowData.sold_price) * rowData.qty;

    const getDeliveryAgents = () => {
        axios.get(`/api/delivery-agents`)
            .then(res => {

                let agents = res.data.map(agent => {
                    return {
                        'label': agent.name,
                        'value': agent.id,
                    }
                });
                setDeliveryAgents(agents);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    };


    const getOrders = async (page = 1) => {
        setLoading(true);
        await axios.get(`/api/orders?page=${page}`)
            .then(res => {
                const { data, meta } = res.data;
                setTotalRecords(meta.total);
                let _orders = data.map((order, i) => {
                    return {
                        ...order,
                        ['serial_number']: i + meta.from
                    }
                })
                setOrders(_orders);


                // console.log(res.data.meta.total);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
        setLoading(false);
    };
    const onPage = async (event) => {
        // let _event = {...event, ['page'] : event.page + 1};
        setLazyParams(event);
        getOrders(event.page + 1);
    }

    const onSort = (event) => {
        console.log('sort');
        setLazyParams(event);
    }

    const onFilter = (event) => {
        console.log('filter');
        event['first'] = 0;
        setLazyParams(event);
        console.log('hai');
    }

    const orderStates = [
        { label: 'Cancelled', value: 'cancelled' },
        { label: 'Delivered', value: 'delivered' },
    ];
    const [staus, setStatus] = useState('created');

    const changeOrderState = (event, rowData) => {

        // console.log(rowData);
        setOrderData(rowData);
        if (event.value == 'on_delivery') {
            setIsModalOpen(true);
        }
        else {
            updateOrderState(event.value, rowData)
        }

    }

    const updateOrderState = (newState, rowData, deliveryAgent = null) => {

        if (newState == 'on_delivery' && !deliveryAgent) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Delivery agent is required' });
            return;
        }
        axios.post(`/api/orders/${rowData.id}`, {
            'newState': newState,
            'deliveryAgent': deliveryAgent,
        })
            .then(res => {
                let objIndex = orders.findIndex((order => order.id == rowData.id));
                let _orders = [...orders];
                _orders[objIndex] = { ...res.data.data, ['serial_number']: _orders[objIndex - 1]?.serial_number ? _orders[objIndex - 1]?.serial_number + 1 : 1 };
                setOrders(_orders);
                toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'Status Changed Successfully' });
            })
            .catch(error => {
                // console.log(error)
                if (error?.response?.status === 400) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.response.data.message });
                }
                if (error.response?.status !== 409) throw error
            })
            .finally(() => setIsModalOpen(false));
    }

    const statusBodyTemplate = rowData => {

        let color;
        let status;
        switch (rowData.current_state) {
            case 'cancelled':
                color = 'danger';
                status = 'Cancelled';
                break;
            case 'payment_collected':
                color = 'success';
                status = 'Payment Collected';
                break;
            case 'on_delivery':
                color = 'Primary';
                status = "On Delivery";
                break;
            case 'consolidated':
                color = 'warning';
                status = 'Consolidated';
                break;
            case 'delivered':
                color = 'warning';
                status = 'Delivered';
                break;
            default:
                color = 'info';
                status = 'Created';

        }
        return <Badge value={status} severity={color} style={{ width: '100px' }}></Badge>

    }
    const confirm = (acceptFunc) => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptFunc(),
            reject: () => setVisibleConfirmDialog(false)
        });
    }

    const changeStatusBodyTemplate = rowData => {
        if (rowData.current_state == 'created') {
            return <Dropdown value={rowData.current_state} options={orderStates} onChange={(event) => confirm(() => changeOrderState(event, rowData))} placeholder="Select State" />
        }
    }
    const checkPrint = rowData => {
        //////change created to delivered for production
        return <span style={{ cursor: 'pointer' }} onClick={() => printInvoice(rowData)}><i className="pi pi-print"></i></span>
    }

    const printInvoice = async (data) => {
        await axios.post(`api/order/${data.id}/actions/generate-invoice`, '')
            .then((res) => {
                setGeneratedInvoice(res.data)
                setShowInvoiceModal(true);
            }).catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong' })
                // console.log(err);
            })
    }

    const dialogFooter = <Button label="Submit" onClick={() => updateOrderState('on_delivery', orderData, selectedDeliveryAgent)} />;


    const componentRef = useRef();

    // if (generatedInvoice) {

    //     history.push('/order/create');
    //     return (
    //         <>

    //             <div className="mb-3 flex justify-content-end">
    //                 <Button label="Back" className="p-button-secondary mr-2" onClick={() => { setGeneratedInvoice(null) }} />
    //                 <ReactToPrint
    //                     trigger={() => <Button label="Print" className="p-button-danger" />}
    //                     content={() => componentRef.current}
    //                 />
    //             </div>

    //             <ComponentToPrint ref={componentRef} generatedInvoice={generatedInvoice} />
    //         </>
    //     )
    // }

    const history = useHistory();
    const navigateTo = () => history.push('/order/create');//eg.history.push('/login');

    const rendorHeader = () => {
        return (
            <>
                <InputText value={searchKey} style={{ width: '100%' }} placeholder="Search Order number or customer name" onChange={e => setSearchKey(e.target.value)} />
            </>
        )
    }

    const searchCustomers = (event) => axios
        .get('/api/customer', {
            params: {
                key: `%${event.query}%`
            }
        })
        .then((res) => {
            setFilteredCustomers(res.data);
        })
        .catch(error => {
            if (error.response?.status !== 409) throw error
        });

    const customerNameBody = rowData => capitalize(rowData.customer.name);

    return (
        <>
            <div>
                <Helmet>
                    <title>Orders</title>
                </Helmet>
            </div>
            <Toast ref={toast} />
            <div className='card-box'>
                <h2 className='mt-0 flex align-items-center'>Orders</h2>
                <Card className='no-pb'>
                    {/* <div className='flex justify-content-between align-items-center mb-2'>
                    <h3>Orders</h3>
                    <Button label="Create Order" aria-label="Submit" onClick={navigateTo} />
                </div> */}
                    <div className="formgrid grid">
                        <div className="field col">
                            <label>Select Customer</label><br />
                            <AutoComplete className='w-full' value={selectedCustomer} suggestions={filteredCustomers}
                                completeMethod={searchCustomers} field="name"
                                onChange={(e) => setSelectedCustomer(e.value)} />
                        </div>
                        <div className="field col">
                            <label htmlFor="from Date">From Date</label>
                            <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='from_date' value={fromDate} onChange={(e) => setFormDate(e.value)} showIcon />
                        </div>

                        <div className="field col">
                            <label htmlFor="To Date">To Date</label>
                            <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='to_date' value={toDate} onChange={(e) => setToDate(e.value)} showIcon />
                        </div>

                        <div className="field flex align-items-end pl-2">
                            <Button label="Filter" type="submit" aria-label="Submit" style={{ marginTop: '5px' }} onClick={filterOrder} />
                        </div>

                    </div>
                    <div className='custom-table'>
                        <DataTable className='br-p-paginator-bottom' header={rendorHeader} dataKey="id" value={orders} lazy filterDisplay="row" paginator rows={lazyParams.rows}
                            loading={loading} responsiveLayout="scroll"
                            first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
                            onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                            onFilter={onFilter} filters={lazyParams.filters}
                            emptyMessage="No orders found.">
                            <Column field="serial_number" header="#"></Column>
                            <Column body={showItemsBtn} header="Order Number"></Column>
                            <Column body={customerNameBody} header="Customer Name"></Column>
                            <Column field="created_at" header="Date & Time"></Column>
                            <Column field="order_lines_count" header="No. of items"></Column>
                            <Column field="discount" header="Discount"></Column>
                            <Column field="sub_total" header="Subtotal"></Column>
                            <Column field="total" header="Total(kwd/unit)"></Column>
                            <Column field="status" body={statusBodyTemplate} header="Status"></Column>
                            <Column field="status" body={changeStatusBodyTemplate} header="Change Status"></Column>
                            <Column field="delivery_agent_name" header="Delivery Agent Name"></Column>
                            <Column field="remarks" header="Remark"></Column>
                            <Column field="print" body={checkPrint} header="Invoice"></Column>
                            {/* <Column body={showItemsBtn} header="Items"></Column> */}
                        </DataTable>
                    </div>
                </Card>
            </div>

            {/* <Dialog header="Assign Delivery Agent" visible={isModalOpen} footer={dialogFooter} style={{ width: '40vw' }} onHide={() => setIsModalOpen(false)}>
                <Dropdown value={selectedDeliveryAgent} options={deliveryAgents} onChange={(e) => setSelectedDeliveryAgent(e.value)} placeholder="Select a Delivery Agent" />
            </Dialog> */}
            <ConfirmDialog/>

            <Dialog header="Order Details" visible={showDialog} onHide={() => setShowDialog(false)} style={{ width: '50vw' }}>
                {orderLines.length &&
                    <>
                        <DataTable value={orderLines}>
                            <Column field="product.name" header="Item"></Column>
                            <Column field="qty" header="Qty"></Column>
                            <Column body={rowData => parseFloat(rowData?.sold_price).toFixed(2)} header="Price"></Column>
                            <Column body={totalPrice} header="Total"></Column>
                            {!_.isEmpty(orderLines.find(orderLine => parseInt(orderLine.returned_qty) > 0)) &&
                                <Column field="returned_qty" header="Total Return"></Column>}
                        </DataTable>
                        {orderLines[0]?.order.extra_charge_amt != 0 &&
                            <div className='flex align-items-center justify-content-between '>
                                <p>{orderLines[0]?.order.extra_charge_desc} (Extra Charge)</p>
                                <p>{orderLines[0]?.order.extra_charge_amt}</p>
                            </div>}

                        <p className='flex align-items-center justify-content-end'>Sub Total: {orderLines[0]?.order?.sub_total}</p>
                        <p className='flex align-items-center justify-content-end'>Discount: {orderLines[0]?.order?.discount}</p>
                        <p className='flex align-items-center justify-content-end'>Discount: {orderLines[0]?.order?.total}</p>
                        <p className=''>Remarks: {orderLines[0]?.order?.remarks}</p>
                    </>}
            </Dialog>

            <Dialog visible={showInvoiceModal} onHide={() => setShowInvoiceModal(false)} style={{ width: '90vw' }}>

                <div className="mb-3 flex justify-content-end">
                    {/* <Button label="Back" className="p-button-secondary mr-2" onClick={() => { setGeneratedInvoice(null) }} /> */}
                    <ReactToPrint
                        trigger={() => <Button label="Print" className="p-button-danger" />}
                        content={() => componentRef.current}
                    />
                </div>

                <ComponentToPrint ref={componentRef} generatedInvoice={generatedInvoice} />
            </Dialog>


            {/* <Dialog header="Order Delivery" visible={isDeliveredModalOpen} style={{ width: '40vw' }} onHide={() => setIsDeliveredModalOpen(false)}>
                <label>OTP</label>
                <InputText type={'number'} min={4} max={4} placeholder="Enter Otp" className="w-full mt-3" onChange={(e) => setOtp(e.target.value)} />

                <p className="mt-3">Send an otp to {orderData?.customer?.email}</p>

                <Button className="mt-3" type="button" label="Verify Otp" onClick={verifyOtp} />
            </Dialog> */}
        </>
    );
}

export default Order;
