import axios from '../../lib/axios';
import { Card } from 'primereact/card'
import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import ManageCurrency from './ManageCurrency';
import { Helmet } from 'react-helmet';
import { Toast } from 'primereact/toast';


// list currencies page
function Currencies() {
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDialog, setshowDialog] = useState(false);
    const [currencyData, setCurrencyData] = useState(false);
    const [flag, setFlag] = useState(false);


    const updateCurrency = rowData => <span className="btn-view" onClick={() => updateCurrencyDetails(rowData)}><i className="pi pi-user-edit"></i></span>
    const updateCurrencyDetails = (data) => {
        setCurrencyData(data);
        setshowDialog(true);
        setFlag(false);
    };

    const hideCreateCustomerDialog = (success) => {
        if (success === 'Updated') {
            showSuccess();
        } else if (success === 'Error') {
            showError();
        }
        setshowDialog(false);
    };

    const fetchdata = () => {
        setLoading(true);
        axios.get(`api/currencies`)
            .then(res => {
                setResult(res.data);
                setLoading(false);
            })
    }
    
    useEffect(() => {
        fetchdata();
        return(()=>{
            setResult([]);
        })
    }, []);

    const currencyUpdated = (data) => {
        let _currencies = [...result];
        let index = _currencies.findIndex(obj => {
            return obj.id === data.id;
        });

        if (index === -1) {
            index = _currencies.length;
            _currencies[index] = data;
            setResult(_currencies);
        } else {
            _currencies[index] = data;
            setResult(_currencies);
        }
    };

    const toast = useRef(null);

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Successfull', detail: 'Action Completed', life: 3000 });
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: 'Error ', detail: 'Unexpected Error', life: 3000 });
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>Currencies</title>
                </Helmet>
            </div>
            <div className='flex justify-content-between mb-4'>
                <h2 className='mt-0 mb-0 flex align-items-center mr-auto'>Currencies</h2>
                <Button label="Create New" icon="pi pi-plus" iconPos="left" onClick={() => { updateCurrencyDetails(); setFlag(true); }} />
            </div>
            <div className='card-box'>
                <Card className='no-px no-px-2'>
                    <div className="col-12 p-0">
                        <div className="formgrid">
                            <DataTable className='datatable-rounded' value={result} responsiveLayout="scroll" emptyMessage="No Currencies found." loading={loading}  >
                                {/* <Column field="id" header="id"></Column> */}
                                <Column field="kuwait_value" header="Kuwait Value"></Column>
                                <Column field="name" header="name"></Column>
                                <Column field="Action" body={updateCurrency} header="Edit"></Column>
                            </DataTable>
                            {showDialog && <ManageCurrency isOpen={showDialog} onHide={hideCreateCustomerDialog} currency_data={currencyData} currencyUpdated={currencyUpdated} flag={flag} />}
                        </div>
                    </div>
                    <Toast ref={toast} />

                </Card>
            </div>
        </>
    )
}

export default Currencies