import React, { useState, useRef } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { AppTopbar } from "./components/AppTopbar";
import classNames from 'classnames';
import { AppMenu } from "./components/AppMenu";
import { Route } from "react-router-dom";
import Stats from "./pages/Stats";
import CreateOrder from "./pages/orders/CreateOrder";
import AppFooter from "./components/AppFooter";
import Products from './pages/products/Products';
import Inventory from './pages/inventory/Inventory';
import Order from './pages/orders/Order';
import Users from './pages/users/Users';
import Shop from './pages/shop/Shop'
import StockTransfer from './pages/stock-transfer/StockTransfer';
import Customer from './pages/customers/Customer';
import CustomerLedger from './pages/customers/CustomerLedger';
import PurchaseDetails from './pages/inventory/PurchaseDetails';
import StockTransferDetails from './pages/stock-transfer/StockTransferDetails';
import Currencies from './pages/currencies/Currencies';
import Return from './pages/return/Return';

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import SuperAdminMenu from './lib/SuperAdminMenu';
import NotSuperAdmin from './lib/NotSuperAdmin';
import Supplier from './pages/supplier/Supplier';
import UploadInvoice from './pages/inventory/UploadInvoice';
import PaymentHistory from './pages/payments/PaymentHistory';
import Consolidate from './pages/orders/Consolidate';
import ReturnOrder from './pages/orders/ReturnOrder';
import Damage from './pages/Damage/Damage';
import CustomerDetails from './pages/customers/CustomerDetails';
import DownloadLedger from './pages/customers/DownloadLedger';
import DownloadPaymentHistory from './pages/payments/DownloadPaymentHistory';
import DownloadPurchaseDetails from './pages/inventory/DownloadPurchaseDetails';

export default function Dashboard() {

    const [newmenu, setMenu] = useState([]);
    const isSuperAdmin = useSelector(e => e.issuperAdmiReducer).isSuperAdmin;

    useEffect(() => {
        if (isSuperAdmin) {
            // The super admin menu
            setMenu(SuperAdminMenu());
        } else {
            //Not super admin menu
            setMenu(NotSuperAdmin())
        }
    }, [])

    const layoutColorMode = 'light'
    const layoutMode = 'static';
    let menuClick = false;
    let mobileTopbarMenuClick = false;
    const copyTooltipRef = useRef();
    const inputStyle = 'filled';
    const ripple = false;
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);

    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={newmenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    <Route path="/" exact render={() => <Stats />} />
                    <Route path="/order/create" render={() => <CreateOrder />} />
                    <Route path="/orders" render={() => <Order />} />
                    <Route path="/order/consolidate" render={() => <Consolidate />} />
                    <Route path="/products/create" render={() => <Products />} />
                    <Route path="/inventory" render={() => <Inventory />} />
                    <Route path="/users" render={() => <Users />} />


                    <Route path="/shops" render={() => <Shop />} />


                    <Route path="/stock-transfer" render={() => <StockTransfer />} />
                    <Route path="/customers" render={() => <Customer />} />
                    <Route path="/customer/:id" render={() => <CustomerDetails />} />
                    <Route path='/customer-ledger' render={() => <CustomerLedger />} />
                    <Route path='/download/customer-ledger' render={() => <DownloadLedger />} />
                    <Route path='/purchase-details' render={() => <PurchaseDetails />} />
                    <Route path='/download-purchase-details' render={() => <DownloadPurchaseDetails />} />
                    <Route path='/stock-transfer-details' render={() => <StockTransferDetails />} />
                    <Route path='/currencies' render={() => <Currencies />} />
                    <Route path='/return' render={() => <Return />} />
                    <Route path='/supplier' render={() => <Supplier />} />
                    <Route path='/invoices' render={() => <UploadInvoice />} />
                    <Route path='/payments/history' render={() => <PaymentHistory />} />
                    <Route path='/download/payments-history' render={() => <DownloadPaymentHistory />} />
                    <Route path='/order/return' render={() => <ReturnOrder />} />
                    <Route path='/damage-entries' render={() => <Damage />} />
                </div>
                <AppFooter />
            </div>
        </div>
    );
}
