import React from 'react'
import { menu } from './menu'
import { useSelector } from 'react-redux';
import { useState } from 'react';

function SuperAdminMenu() {
    return (
        menu
    )
}

export default SuperAdminMenu