import React from 'react';
import PropTypes from 'prop-types'
import {Message} from "primereact/message";


function ErrorList(props){
    return props.errors.map((error) => {
        return (
            <Message severity="error" key={error.source.pointer} text={error.detail}/>
        )
    });
}

ErrorList.propTypes = {
    errors: PropTypes.array
}

export default ErrorList
