import React, { useState, useEffect, useRef } from 'react'
import axios from "../../lib/axios";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ManageUsers from './ManageUsers';
import { Toast } from 'primereact/toast';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';


// list all users
function Users() {
    const userRoles = [];

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [user_data, setUserData] = useState(false);
    const [shops, setShops] = useState([]);
    const [roles, setRoles] = useState([]);
    const [isPasswordResetOpen, setIsPasswordResetOpen] = useState(false);

    const [showDialog, setshowDialog] = useState(false);

    const isSuperAdmin = useSelector(e => e.issuperAdmiReducer).isSuperAdmin;

    const [searchKey, setSearchKey] = useState('');
    const [createCustomerErrors, setCreateCustomerErrors] = useState([]);

    useEffect(() => {
        fetchUsers()
        populateShopsData();
        populateRoles();

        return () => {
            setUsers([]);
            setShops([]);
            setRoles([]);
        }
    }, [])

    const fetchUsers = async () => {
        setLoading(true);
        await axios.get(`/api/user`)
            .then(res => {
                setUsers(res.data);
                res.data.map(e => {
                    userRoles.push(e.roles[0].name);
                })
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
        setLoading(false);
    }

    const populateShopsData = async () => {
        await axios.get(`/api/shop`)
            .then(res => {
                setShops(res.data);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }

    const populateRoles = async () => {
        await axios.get(`/api/roles`)
            .then(res => {
                const _roles = [...res.data];
                _roles.splice(0, 1);
                setRoles(_roles);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }

    const showDialogFn = () => {
        setshowDialog(true)
    }

    const hideCreateCustomerDialog = (success) => {
        if (success) {
            showSuccessToast(success)
        }
        setUserData(false)
        setshowDialog(false)
    }

    const showSuccessToast = (success) => {
        fetchUsers()
        toast.current.show({ severity: 'success', summary: 'Success Message', detail: `User Successfully ${success === 'Created' ? 'Created' : 'Updated'}`, life: 3000 });
    }

    const toast = useRef(null);

    const updateUser = (user) => {
        setUserData(user)
        setshowDialog(true)
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            password_confirmation: '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.password) {
                errors.password = 'Password is required';
            }

            if (data.password && data.password.length !== 8) {
                errors.password = 'Password must contains atleast 8 charactors';
            }

            if (!data.password_confirmation) {
                errors.password_confirmation = 'Confirm password is required';
            }

            if (data.password_confirmation && data.password_confirmation !== data.password) {
                errors.password_confirmation = 'Confirm password and Password should be equal';
            }

            return errors;
        },
        onSubmit: (data) => {

            if (user_data) {

                axios
                    .post(`/api/change-password/${user_data?.id}`, {
                        ...data
                    })
                    .then((res) => {
                        setIsPasswordResetOpen(false);
                        setUserData(null);
                        formik.resetForm();
                        toast.current.show({ severity: 'success', summary: 'Success Message', detail: "Password reset successfully", life: 3000 });

                    })
                    .catch(error => {
                        if (error) {
                            if (error.response.status === 400) {
                                let err = Object.values(error?.response?.data)
                                setCreateCustomerErrors(err)
                            }
                        } else {
                            console.log(error)
                        }

                    }).finally(() => setLoading(false));
            }

        }
    });

    const actionBody = (rowData) => {
        const actionItems = [
            {
                label: 'Edit',
                icon: 'pi pi-user-edit',
                command: (e) => {
                    updateUser(rowData);
                }
            },
            {
                label: 'Reset password',
                icon: 'pi pi-refresh',
                command: (e) => {
                    setUserData(rowData);
                    setIsPasswordResetOpen(true);
                }
            }
        ];

        return <SplitButton label="Action" buttonClassName='p-button-sm p-button-text' className="p-button-sm p-button-text" model={actionItems}></SplitButton>

    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    const Roles = rowData => rowData.roles[0]?.name;

    const searchUser = (e) => {

        setSearchKey(e.target.value);
        axios.get('api/users/search', {
            params: {
                key: e.target.value
            }
        }).then(res => {
            setUsers(res.data);
        })
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={searchKey} onChange={searchUser} placeholder="Search User" id='key_word' />
                </span>
                <button typeof='sumbit' style={{ display: 'none' }} />

            </div>
        )
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>Users</title>
                </Helmet>
            </div>

            <div className='flex justify-content-between mb-4'>
                <h2 className='mt-0 mb-0 flex align-items-center'>Users</h2>
                <Button label="Create New" icon="pi pi-plus" iconPos="left" onClick={showDialogFn} />
            </div>
            <Card className='no-px no-px-2'>

                <div className='custom-table'>
                    <DataTable className='datatable-rounded tr-p-datatable-header' dataKey="id" value={users} lazy filterDisplay="row"
                        header={renderHeader} loading={loading} responsiveLayout="scroll"
                        // totalRecords={totalRecords} 
                        globalFilterFields={['name', 'description']}
                        emptyMessage="No user found.">
                        {/* <Column field="id" header="ID"></Column> */}
                        <Column field="name" header="Name"></Column>
                        <Column field="email" header="email"></Column>
                        {isSuperAdmin &&
                            <Column field="shop.name" header="Branch"></Column>
                        }
                        <Column body={Roles} header="Roles"></Column>
                        <Column field="Action" body={actionBody} header="Action"></Column>
                    </DataTable>
                </div>

                {showDialog && <ManageUsers isOpen={showDialog} onHide={hideCreateCustomerDialog} user_data={user_data} shops={shops} roles={roles} />}

                <Dialog header="Password Reset" visible={isPasswordResetOpen} style={{ width: '450px' }} modal
                    className="p-fluid" onHide={() => {
                        setUserData(null);
                        formik.resetForm();
                        setIsPasswordResetOpen(false)
                    }
                    }>
                    {createCustomerErrors.map((val, k) => {
                        return (
                            <Message severity="error" key={k} text={val} />
                        )
                    })}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="field">
                            <label htmlFor="password">Password</label>
                            <InputText type={"password"} id="password" name="password" value={formik.values.password} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                            {getFormErrorMessage('password')}
                        </div>
                        <div className="field">
                            <label htmlFor="password_confirmation">Confirm Password</label>
                            <InputText type={"password"} id="password_confirmation" name="password_confirmation" value={formik.values.password_confirmation} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('password_confirmation') })} />
                            {getFormErrorMessage('password_confirmation')}
                        </div>
                        <Button type="submit" label={`${loading ? "loading.." : "Reset"}`} disabled={loading} />
                    </form>
                </Dialog>
                <Toast ref={toast} />

            </Card>
        </>
    )
}

export default Users