import React, { useState, useEffect, useRef } from 'react'
import axios from "../../lib/axios";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';;
import { Helmet } from 'react-helmet';

import CreateProductDialog from './components/CreateProductDialog';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { SplitButton } from 'primereact/splitbutton';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import fileDownload from 'js-file-download';
// import classNames from "classnames";


// list products page
export default function CreateProduct() {

    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [products, setproducts] = useState([]);
    const [key, setKey] = useState('');

    const isSuperAdmin = useSelector(e => e.issuperAdmiReducer).isSuperAdmin;


    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            'attributes.name': { value: '', matchMode: 'contains' },
            'attributes.description': { value: '', matchMode: 'contains' },
            'attributes.cost': { value: '', matchMode: 'contains' },
        }
    });


    useEffect(() => {
        getProducts();
        return () => {
            setproducts([]);
        }
    }, []);

    const hideCreateProductDialog = (success) => {
        if (success === 'Success') {
            getProducts();
            showSuccess()
        } else if (success === 'Error') {
            showError();
        }
        setshowCreateDialog(false);
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        //const key_word = document.getElementById('key_word').value;
        setKey(value);
        axios.get('/api/product', {
            params: {
                key: `%${value}%`
            }
        })
            .then((res) => {
                setproducts(res.data.data)
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong!' });
                if (error.response?.status !== 409) throw error
            });
    }

    let searh_key;
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }
    // const exportExcel = () => {
    //     import('xlsx').then(xlsx => {
    //         let _products = products.map(entry => {
    //             return {
    //                 name: entry?.name,
    //                 description: entry?.description,
    //                 brand: entry?.brand,
    //                 stock: entry?.stock,
    //                 cost: entry?.cost,
    //                 stock_amount: parseFloat(entry?.cost) * parseFloat(entry?.stock),
    //             }
    //         })
    //         const worksheet = xlsx.utils.json_to_sheet(_products);
    //         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //         const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //         saveAsExcelFile(excelBuffer, 'Products');
    //     });
    // }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end align-items-center">
                {/* <Button label="Export excel" type="button" onClick={ex ?portExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" /> */}
                {exportDetails?.status === 'success' ?
                    <Button disabled={exportLoading} label="Download excel" type="button" onClick={downLoadFile} className="p-button-success mr-2" data-pr-tooltip="XLS" />
                    :
                    <Button disabled={exportLoading} label={exportLoading ? 'Export Started' : "Export excel"} type="button" onClick={exportProducts} className="p-button-success mr-2" data-pr-tooltip="XLS" />}
            </div>
        )
    }

    const downLoadFile = () => {
        axios({
            method: 'get',
            url: `/api/download-product-excel/${exportDetails?.id}`,
            responseType: 'blob'
        }).then(res => {
            fileDownload(res.data, exportDetails.file_name);

        }).catch(error => {
            console.log(error);
            if (error.response?.status !== 409) throw error
        });
    }


    const getProducts = async (page = 1) => {
        setLoading(true);
        await axios.get(`/api/products?page=${page}&key=%25${key}%25`)
            .then(res => {
                const { data, meta } = res.data;
                setTotalRecords(meta?.total);
                let products = data.map((product, i) => {
                    return {
                        ...product,
                        ['serial_number']: i + meta?.from
                    }
                })
                setproducts(products);

            })
            .catch(error => {
                console.log(error)
                if (error.response?.status !== 409) throw error
            });
        setLoading(false);
    };

    const onPage = async (event) => {

        setLazyParams(event);
        getProducts(event.page + 1);
    }

    const onSort = (event) => {
        console.log('sort');
        setLazyParams(event);
    }

    const onFilter = (event) => {
        console.log('filter');
        event['first'] = 0;
        setLazyParams(event);
        console.log('hai');
    }

    const [exportDetails, setExportDetails] = useState(null);
    const [exportLoading, setExportLoading] = useState(false);

    const exportProducts = () => {
        setExportLoading(true);
        axios.get(`api/start-to-export-products`)
            .then(response => {
                setExportDetails(response?.data?.excelExport.id);
                getExcelExportDetails(response?.data?.excelExport.id)
            }).catch(err => console.log(err))
            .finally(() => {
                setExportLoading(false);
            })
    }

    const getExcelExportDetails = (id) => {
        console.log('id',id);
        axios.get(`api/export-excel/${id}`)
            .then(response => {
                if (response?.data === 'processing') {
                    getExcelExportDetails(id);
                } else {
                    setExportDetails(response?.data);
                }
            }).catch(err => console.log(err))
            .finally(() => {
                setExportLoading(false);
            })

    }

    const header = !isSuperAdmin ? renderHeader() : null;

    const editProduct = rowData => <span className="btn-view" onClick={() => updateProduct(rowData)}><i className="pi pi-user-edit"></i></span>
    const [showCreateDialog, setshowCreateDialog] = useState(false);
    const [product_data, setProductData] = useState(false);
    const [edit, setEdit] = useState(false);

    const updateProduct = (product) => {
        setProductData(product);
        setshowCreateDialog(true);
        setEdit(true)
    };

    const toast = useRef(null);

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Successfull', detail: 'Action Completed', life: 3000 });
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: 'Error ', detail: 'Unexpected Error', life: 3000 });
    }

    const statusBody = rowData => <Badge value={rowData.is_active ? 'Activated' : 'Deactivated'} severity={rowData.is_active ? 'success' : 'danger'}></Badge>
    const stockAmountBody = rowData => (parseFloat(rowData.stock) * parseFloat(rowData.cost)).toFixed(2)

    const actionProduct = rowData => {
        const ActionItems = [
            {
                label: 'Edit',
                icon: 'pi pi-user-edit',
                command: (e) => {
                    updateProduct(rowData);
                }
            },
            {
                label: !rowData.is_active ? 'Active' : 'Deactive',
                icon: !rowData.is_active ? 'pi pi-check' : 'pi pi-times',
                command: (e) => {
                    confirmDialog({
                        message: 'Are you sure you want to proceed?',
                        header: 'Confirmation',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: !rowData.is_active ? 'Active' : 'Deactive',
                        accept: () => activeOrDeactivate(rowData),
                    });

                }
            }
        ];
        return <SplitButton label="Action" buttonClassName='p-button-sm p-button-text' className="p-button-sm p-button-text" model={ActionItems}></SplitButton>
    }

    const activeOrDeactivate = async (rowData) => {
        await axios.patch(`/api/product-activation/${rowData?.id}`)
            .then(res => {
                let _products = [...products];
                const index = _products.findIndex(product => product.id === rowData?.id);

                _products[index]['is_active'] = !rowData?.is_active;
                setproducts(_products);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }


    const descriptionBodyTemplate = (rowData) => {
        return (<>
            <p title={rowData.description} style={{maxWidth:'600px'}}>{rowData.description}</p>
        </>);
    };

    return (
        <>
            <div>
                <Helmet>
                    <title>Manage Products</title>
                </Helmet>
            </div>
            <div className="flex justify-content-end align-items-center mb-4">
                <h2 className='mt-0 mb-0 flex align-items-center mr-auto'>Products</h2>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={searh_key} onChange={onGlobalFilterChange} placeholder="Search Product" id='key_word' />
                </span>
                <button typeof='sumbit' style={{ display: 'none' }} />

            </div>

            <Card className='no-px no-px-2'>
                <ConfirmDialog />
                {isSuperAdmin &&
                    <div className='flex justify-content-end mb-2'>
                        <Button className='mt-2' label="Create New" icon="pi pi-plus" iconPos="left" onClick={() => { setEdit(false); setProductData([]); return setshowCreateDialog(true) }} />
                    </div>}

                <div className='custom-table pagination-bottom-rounded'>
                    <DataTable className='tr-p-datatable-wrapper br-p-paginator-bottom' dataKey="id" value={products} lazy filterDisplay="row" paginator rows={lazyParams.rows}
                        loading={loading} responsiveLayout="scroll" header={header}
                        first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
                        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                        onFilter={onFilter} filters={lazyParams.filters} globalFilterFields={['name', 'description']}
                        emptyMessage="No products found.">

                        {/* <Column field="id" header="ID"></Column> */}
                        <Column field="serial_number" header="#"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column field="description" header="Description" body={descriptionBodyTemplate}> </Column>
                        <Column field="brand" header="Brand"></Column>
                        <Column field="cost" header="Cost"></Column>

                        {!isSuperAdmin && <Column field="stock" header="Stock"></Column>}
                        {!isSuperAdmin && <Column body={stockAmountBody} header="Stock Amount"></Column>}
                        {isSuperAdmin && <Column body={statusBody} header="Status"></Column>}
                        {isSuperAdmin && <Column body={actionProduct} header="Action"></Column>}

                    </DataTable>
                </div>
                <Toast ref={toast} />
            </Card>

            {showCreateDialog && <CreateProductDialog isOpen={showCreateDialog} onHide={hideCreateProductDialog} product_data={product_data} edit={edit} />}

        </>

    )
}
