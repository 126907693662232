import React, { useState, useEffect, useRef } from 'react';

import _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import axios from "../../lib/axios";
import CreateCustomerDialog from "./components/CreateCustomerDialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { useHistory } from 'react-router-dom';
import { numericOnly } from '../../lib/helper';
import Helmet from 'react-helmet';


// create order form page
export default function CreateOrder(props) {
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [filteredCustomers, setFilteredCustomers] = useState(null);
    const [customerCreateDialog, setCustomerCreateDialog] = useState(false);

    const [lineItems, setLineItems] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchProductQuery, setSearchProductQuery] = useState('');
    const [discount, setDiscount] = useState();
    const [total, setTotal] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [isExtraCharge, setIsExtraCharge] = useState(false);
    const [extraCharge, setExtraCharge] = useState();
    const [extraChargeDesc, setExtraChargeDesc] = useState('');
    const [remarks, setRemarks] = useState('');
    const toast = useRef(null);
    const history = useHistory();

    const createdOrder = () => {
        toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'Order created successfully' });
        setSelectedCustomer(null);
        setLineItems([]);
        setDiscount();
        setIsExtraCharge(false);
        setExtraCharge();
        setExtraChargeDesc('');
        setRemarks('');
        setTotal(0);

        setTimeout(() => history.push('/orders'), 500);
    }

    useEffect(() => {
        if (selectedProduct && _.findIndex(lineItems, (item) => {
            return item.id === selectedProduct.id;
        }) === -1) {
            const item = selectedProduct;
            // if (item.stock < 1) {
            //     toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'This product is outstock' });
            // } else {
            item.id = selectedProduct.id;
            item.qty = 1;
            item.price = item.cost
            item.subTotal = item.price * item.qty;
            setLineItems([...lineItems, item]);
            setSearchProductQuery(null);
            // }
        }
        setSelectedProduct(null);
    }, [selectedProduct]);

    useEffect(() => {
        setExtraCharge();
        setExtraChargeDesc('');
    }, [isExtraCharge])

    useEffect(() => {

        const subTotal = lineItems.reduce((prevTotal, item) => {
            return prevTotal + parseFloat(item.subTotal)
        }, 0);
        // console.log(lineItems);

        // subTotal += extraCharge;
        setSubTotal(parseFloat(subTotal) + (isNaN(extraCharge) ? 0 : extraCharge))
        setTotal(parseFloat(subTotal) + (isNaN(extraCharge) ? 0 : extraCharge) - (isNaN(discount) ? 0 : discount));

    }, [lineItems, discount, extraCharge]);

    const searchCustomers = (event) => axios
        .get('/api/customer', {
            params: {
                key: `%${event.query}%`
            }
        })
        .then((res) => {
            setFilteredCustomers(res.data);
        })
        .catch(error => {
            if (error.response?.status !== 409) throw error
        });

    const searchProducts = async (event) => {
        try {
            const res = await axios
                .get('/api/product', {
                    params: {
                        key: `%${event.query}%`
                    }
                });
            setFilteredProducts(res.data.data);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong!' });
            if (error.response?.status !== 409)
                throw error;
        }
    }

    const openCreateCustomer = () => {
        setCustomerCreateDialog(true);
    }
    const hideCreateCustomerDialog = () => {
        setCustomerCreateDialog(false);
    };

    const onCreatedCustomer = (customer) => {
        hideCreateCustomerDialog();
        setSelectedCustomer(customer);
    }


    function onQtyChange(props, value) {
        const updated = [...lineItems];
        // if (updated[props.rowIndex].stock < value) {
        //     toast.current.show({ severity: 'error', summary: 'Error Message', detail: `Only ${updated[props.rowIndex].stock} stock left` });
        // } else {
        updated[props.rowIndex].qty = value;
        updated[props.rowIndex].subTotal = parseFloat(updated[props.rowIndex].qty * updated[props.rowIndex].price).toFixed(2);
        setLineItems(updated);

        // }

    }

    function onPriceChange(props, value) {
        const updated = [...lineItems];
        updated[props.rowIndex].price = value;
        updated[props.rowIndex].subTotal = parseFloat(updated[props.rowIndex].qty * updated[props.rowIndex].price).toFixed(2);
        setLineItems(updated);
    }

    function onDescriptionChange(props, value) {
        const updated = [...lineItems];
        updated[props.rowIndex].description = value;
        setLineItems(updated);
    }

    function createOrder() {
        if (!selectedCustomer) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Customer is required!' });
        } else if (lineItems.length == 0) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'At least one product is required!' });
        } else {

            if (isExtraCharge) {
                if (!extraChargeDesc) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Extra charge Description is required!' });
                    return;
                }

                if (!extraCharge) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Extra charge is required!' });
                    return;
                }
            }

            if (subTotal < parseFloat(discount)) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'The dicount amount cannot be greater than the total amount' });
                return;
            }

            setLoading(true);
            axios.post('/api/orders', {
                data: {
                    type: "orders",
                    attributes: {
                        discount: discount,
                        extraChargeDesc: extraChargeDesc,
                        extraCharge: extraCharge,
                        remarks: remarks
                    },
                    relationships: {
                        customer: {
                            data: {
                                type: "customers",
                                id: selectedCustomer.id
                            }
                        },
                        orderLines: {
                            data: lineItems.map((item) => {
                                item.type = "order-lines"
                                return item;
                            }),
                        },
                    },
                },
            }).then((res) => {
                console.log(res.status);
                createdOrder();
            }).catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong' });

            }).finally(() => setLoading(false))
        }
    }

    const removeItem = (id) => {
        const productItems = [...lineItems];

        const index = productItems.findIndex(item => item.id === id);
        productItems.splice(index, 1);
        setLineItems(productItems);

    }



    const removeItemBody = (rowData) => <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined p-button-icon-only" onClick={() => removeItem(rowData.id)}> </Button>


    return (
        <>
            <Helmet>
                <title>Create Order</title>
            </Helmet>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col">
                    <h2 className='mt-0 flex align-items-center'>New Sale</h2>
                    <div className="card p-fluid">
                        <div className="field">
                            <label htmlFor="name1">Customer</label>
                            <div className="flex w-full px-0 justify-content-between">
                                <div className="w-full mr-3">
                                    <AutoComplete value={selectedCustomer} suggestions={filteredCustomers}
                                        completeMethod={searchCustomers} field="name"
                                        onChange={(e) => setSelectedCustomer(e.value)} />
                                </div>
                                <div className="">
                                    <Button label="Create New" onClick={openCreateCustomer} disabled={selectedCustomer} />
                                </div>
                            </div>
                        </div>

                        <div className="field mt-3">
                            <label>Search Product</label>
                            <AutoComplete suggestions={filteredProducts}
                                value={searchProductQuery}
                                completeMethod={searchProducts}
                                field="name"
                                onSelect={(e) => setSelectedProduct(e.value)}
                                onChange={(e) => setSearchProductQuery(e.value)}
                                placeholder='Search Product'
                            />
                        </div>
                        <div className=''>
                            <h4>Line Items</h4>
                            <DataTable value={lineItems}>
                                <Column field="name" header="Name" />
                                <Column field="description" header="Description" editor={(props, field) => {
                                    return (<InputText
                                        value={props.rowData[field]}
                                        onChange={(e) => onDescriptionChange(props, e.target.value)}
                                    />);
                                }} />
                                <Column field="cost" header="Cost" />
                                <Column field="price" header="Price (kwd/unit)" editor={(props, field) => {
                                    return <InputNumber
                                        value={props.rowData[field]}
                                        onChange={(e) => onPriceChange(props, e.value)} mode="decimal" minFractionDigits={2} />
                                }} />
                                <Column field="qty" header="Quantity" editor={(props, field) => {
                                    return (<InputNumber
                                        value={props.rowData[field]}
                                        onChange={(e) => onQtyChange(props, e.value)}
                                    />);
                                }} />
                                <Column field="subTotal" header="Sub Total" />
                                <Column body={removeItemBody} header="" />
                            </DataTable>

                        </div>

                        <div className="">
                            <div className='grid mt-3'>
                                <div className="col-3 flex align-items-center">
                                    <Checkbox id="extracharge" onChange={e => setIsExtraCharge(e.checked)} checked={isExtraCharge}></Checkbox>
                                    <label htmlFor="extracharge" className="p-checkbox-label ml-2">Extra Charge</label>
                                </div>
                                {isExtraCharge &&
                                    <>
                                        <div className="col-6 mb-2">
                                            <InputText value={extraChargeDesc} placeholder="Enter Description" onChange={e => setExtraChargeDesc(e.target.value)} />
                                        </div>
                                        <div className="col-3 mb-2">
                                            <InputNumber value={extraCharge} placeholder="Enter amount" mode="decimal" onChange={e => { setExtraCharge((e.value)?(parseFloat(e.value)):(0))}} maxFractionDigits={2}/>
                                        </div>
                                    </>}


                            </div>

                            <div className="grid">
                                <div className="col-12  field mt-2">
                                    <label htmlFor="name1">Remark</label>
                                    <InputText value={remarks} placeholder="Enter Remark" onChange={e => setRemarks(e.target.value)} />
                                </div>
                                <div className="col-4  field">
                                    <label>Sub Total</label>
                                    <InputNumber value={subTotal} suffix=" KWD" readOnly mode="decimal" minFractionDigits={2} />
                                </div>
                                <div className="col-4  field">
                                    <label>Discount amount</label>
                                    <InputNumber value={discount} mode="decimal" minFractionDigits={2} onChange={(e) => {
                                        if (subTotal < parseFloat(e.value)) {
                                            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'The discount amount cannot be greater than the total amount' });
                                            return;
                                        }
                                        setDiscount(e.value);
                                    }
                                    }
                                        suffix=" KWD" />
                                </div>
                                <div className="col-4  field">
                                    <label>Total</label>
                                    <InputNumber value={total} suffix=" KWD" minFractionDigits={2} readOnly />
                                </div>
                            </div>

                            <div className="card-footer flex justify-content-end">
                                <Button className='' label={`${loading ? "loading.." : "Create Order"}`} disabled={loading} onClick={createOrder} />
                            </div>
                        </div>

                    </div>
                </div>

                {/*checking used for to contoll useEffect inside child */}
                {
                    customerCreateDialog &&
                    <CreateCustomerDialog visible={customerCreateDialog} onCreatedCustomer={onCreatedCustomer}
                        onHide={hideCreateCustomerDialog} />
                }

            </div>
        </>
    );
} 