import Axios from 'axios'
import qs from 'qs';


let axios = Axios.create({
    baseURL: `${process.env.MIX_APP_URL}`,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept':'application/vnd.api+json',
        'Content-Type':'application/vnd.api+json'
    },
    withCredentials: true,
    paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
    },
})

axios.interceptors.response.use((response) => response, (error) => {
    // whatever you want to do with the error

    if(error.response.status === 419 || error.response.status === 401){
        window.location.href ="/login"
    }
   
  });


export default axios
