import React from 'react';

const ReportCard = ({title, value, icon}) => {
    return (
        <div className="card mb-0">
            <div className="flex justify-content-between">
                <div>
                    <span className="block text-500 font-medium mb-3">{title}</span>
                    <div className="text-900 font-medium text-xl">{value}</div>
                </div>
                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                    <i className={icon} />
                </div>
            </div>
            {/* <span className="text-green-500 font-medium">24 new </span>
                        <span className="text-500">since last visit</span> */}
        </div>
    );
}

export default ReportCard;
