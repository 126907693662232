import { useState } from "react";

export const useForm = intialValue => {
    const [values, setValues] = useState(intialValue);

    return [
        values,
        (name, value) => {
            setValues({
                ...values,
                [name]: value
            });
        }
    ]
}