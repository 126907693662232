import React, { useRef, useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import axios from '../../lib/axios';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Image } from 'primereact/image';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Helmet from 'react-helmet';



// upload invoice image page
const UploadInvoice = () => {

    const [name, setName] = useState('');
    const [remark, setRemark] = useState('');
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const toast = useRef(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            'attributes.name': { value: '', matchMode: 'contains' },
            'attributes.description': { value: '', matchMode: 'contains' },
            'attributes.cost': { value: '', matchMode: 'contains' },
        }
    });

    useEffect(() => {
        getInvoice();
        return (() => {
            setTotalRecords(0);
            setInvoices([]);
        });
    }, []);

    const getInvoice = (page = 1, formDate = null, toDate = null) => {
        setLoading(true);
        axios.get('api/purchases/upload-invoice', {
            params: {
                page: page,
                fromDate: formDate,
                toDate: toDate,
            }
        }).then(res => {
            setTotalRecords(res.data.total);
            setInvoices(res.data.data);
        }).catch(error => {
            console.log(error);
        })
            .then(() => {
                setLoading(false);
            });
    }

    const myUploader = (event) => {
        //event.files == files to upload
        if (!name) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Name is required' });
            return;
        }

        const formData = new FormData();
        formData.append('file', event.files[0]);
        formData.append('name', name);
        formData.append('remark', remark);

        axios.post('api/purchases/upload-invoice', formData)
            .then(res => {
                const _invoices = [...invoices];
                _invoices.unshift(res.data.data);
                setInvoices(_invoices);
                setIsModalOpen(false);
                toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully Added' });
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong' });
            })
    }

    const filter = () => {
        if (!fromDate && !toDate) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Date range is required!' });
            return;
        }
        getInvoice(1, fromDate, toDate);
    }
    const onPage = async (event) => {

        setLazyParams(event);
        getInvoice(event.page + 1);
    }
    const invoiceImageTemplate = (rowData) => <Image src={rowData.invoice_file} alt="Invoice" width="100" preview />

    const deleteBtnBody = rowData => <span className='btn-view' onClick={() => confirm(() => deleteInvoice(rowData))} ><i className='pi pi-trash'></i></span>

    const deleteInvoice = purchaseInvoice => {
        axios.delete(`api/purchases/delete-invoice/${purchaseInvoice?.id}`)
            .then(res => {
                // console.log(res.data);
                let _invoices = [...invoices];
                const index = _invoices.findIndex(invoice => invoice.id === purchaseInvoice.id);
                _invoices.splice(index, 1);
                setInvoices(_invoices);
                toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully Deleted' });


            }).catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Somethig went wrong' });
            })
    }

    const confirm = (acceptFunc) => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptFunc()
        });
    }


    return (
        <>
            <Helmet>
                <title>Upload Invoice</title>
            </Helmet>
            <ConfirmDialog/>
            <div className='flex justify-content-between align-items-center mb-4'>
                <h2 className='mt-0 mb-0 flex align-items-center'>Invoices</h2>
                <Button label="Add Invoice" icon="pi pi-plus" onClick={() => setIsModalOpen(true)} />
            </div>
            <Toast ref={toast} />
            <div className='card-box'>
                <Card className='no-pb'>
                    <div className="grid">
                        <div className="field col">
                            <label htmlFor="from Date">From Date</label><br />
                            <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='from_date' value={fromDate} onChange={(e) => setFromDate(moment(e.value).toISOString(true))} showIcon />

                        </div>

                        <div className="field col">
                            <label htmlFor="To Date">To Date</label><br />
                            <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='to_date' value={toDate} onChange={(e) => setToDate(moment(e.value).toISOString(true))} showIcon />

                        </div>

                        <div className="field flex align-items-end p-2">
                            <br />
                            <Button className='mt-0' label="Filter" type="type" onClick={filter} style={{ marginTop: '5px' }} />
                        </div>

                    </div>
                    <div className='custom-table'>
                        <DataTable className='br-p-paginator-bottom' value={invoices} responsiveLayout="scroll" loading={loading}
                            lazy filterDisplay="row" paginator rows={lazyParams.rows} first={lazyParams.first}
                            totalRecords={totalRecords} onPage={onPage}
                            emptyMessage="No Invoices found." >
                            <Column field="date" header="Date"></Column>
                            <Column field="name" header="Name"></Column>
                            <Column field="image" header="Invoice" body={invoiceImageTemplate}></Column>
                            <Column field="remark" header="Remark"></Column>
                            <Column header="Action" body={deleteBtnBody}></Column>
                        </DataTable>
                    </div>
                </Card>
            </div>


            <Dialog header="Upload Invoice" visible={isModalOpen} style={{ width: '50vw' }} onHide={() => setIsModalOpen(false)}>
                <div className="grid">
                    <div className="field col-4">
                        <label htmlFor="name">Name</label>
                        <InputText id="name" name="name" className="w-full" value={name} onChange={e => setName(e.target.value)} />
                    </div>
                    <div className="field col">
                        <label htmlFor="remark">Remark</label>
                        <InputText id="remark" name="remark" className="w-full" value={remark} onChange={e => setRemark(e.target.value)} />
                    </div>
                </div>
                <FileUpload name="file" customUpload uploadHandler={myUploader}></FileUpload>
            </Dialog>


        </>
    );
}

export default UploadInvoice;