import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../lib/axios';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Toast } from 'primereact/toast';
import fileDownload from 'js-file-download';



// download payment history page

const DownloadPaymentHistory = () => {
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [customer, setCustomer] = useState([]);
    const toasts = useRef(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedPayments, setSelectedPayments] = useState(null);
    const [generatedList, setGeneratedList] = useState([]);

    const searchCustomer = (event) => {
        let _filteredCustomers;
        axios.get(`/api/customer?key=${event.query}`)
            .then(res => {
                _filteredCustomers = res.data.filter((e) => {
                    return e.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
                setCustomer(_filteredCustomers);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }

    const getGeneratedList = () => {
        axios.get(`/api/generated-pdf-list?type=payment_history`)
            .then(res => {
                setGeneratedList(res.data);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }

    const getPaymentHistory = () => {

        if (!selectedCustomer?.id) {

            return;
        }

        if (!fromDate) {
            toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'From date required' });
            return;
        }

        if (!toDate) {
            toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'To date required' });
            return;
        }


        setTimeout(() => {
            getGeneratedList();
        }, 1000);

        axios.post('api/customer/generate/payment-history', {
            customer_id: selectedCustomer.id,
            fromDate: moment(fromDate).toISOString(true),
            toDate: moment(toDate).toISOString(true)
        })
            .then(res => {
                // setPaymentHistory(res.data.data);
            }).catch(error => {
                toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong' });
            }).finally(() => {
                getGeneratedList();
            })
    }

    const onSelectionChange = (event) => {
        const value = event.value;
        setSelectedPayments(value);
        setSelectAll(value.length === totalRecords);
    }

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            setSelectAll(true);
            setSelectedPayments(paymentHistory);
        }
        else {
            setSelectAll(false);
            setSelectedPayments([]);
        }
    }



    useEffect(() => {
        getGeneratedList();
    }, []);

    const downLoadBtnBody = rowData => {
        if (rowData.status === 'success') {
            return <Button type="button" label='download' onClick={() => downLoadFile(rowData)} />
        }
        return null;

    }

    const downLoadFile = (rowData) => {
        axios({
            method: 'get',
            url: `/api/download-pdf/${rowData.id}`,
            responseType: 'blob'
        }).then(res => {
            fileDownload(res.data, rowData.file_name);

        })
            .catch(error => {
                console.log(error);
                if (error.response?.status !== 409) throw error
            });
    }

    return (
        <>
            <Helmet>
                <title>PDF: Payment History</title>
            </Helmet>

            <h2 className='mt-0 flex align-items-center mr-auto'>PDF: Payment History</h2>
            <div className='card-box'>
                <Card>
                    <div className="formgrid grid">

                        <div className="field col">
                            <label htmlFor="select_customer">Select Customer</label><br />
                            <AutoComplete className='w-full' placeholder="Select customer" inputId='select_customer' name='customer' value={selectedCustomer} suggestions={customer} completeMethod={searchCustomer} field="name" onChange={(e) => setSelectedCustomer(e.value)} />
                        </div>

                        <div className="field col">
                            <label htmlFor="from Date">From Date</label>
                            <Calendar className='w-full' id="icon" name='fromdate' value={fromDate} onChange={(e) => setFromDate(e.value)} showIcon required />
                        </div>

                        <div className="field col">
                            <label htmlFor="To Date">To Date</label>
                            <Calendar className='w-full' id="icon" name='todate' value={toDate} onChange={(e) => setToDate(e.value)} showIcon required />
                        </div>

                        <div className="field flex align-items-end pl-2">
                            <Button label="Generate" className="" type="button" aria-label="Generate" onClick={getPaymentHistory} />
                        </div>


                    </div>

                    <DataTable className='border-bottom-rounded' value={generatedList} responsiveLayout="scroll" loading={loading}>
                        <Column field="from_date" header="From Date"></Column>
                        <Column field="to_date" header="To Date"></Column>
                        <Column field="file_name" header="File Name"></Column>
                        <Column field="status" header="Status"></Column>
                        <Column body={downLoadBtnBody} header="Action"></Column>
                    </DataTable>

                    <Toast ref={toasts} />
                </Card>
            </div>
        </>
    );
}

export default DownloadPaymentHistory;
