import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React from 'react'



// manage return order popup

function ManageReturn({ isOpen, onHide }) {
    return (
        <>
            <Dialog header={`header`} visible={isOpen} style={{ width: '450px' }} modal className="p-fluid" onHide={onHide}>
                <form className="p-fluid">
                    <div className="field">
                        <label htmlFor="product">Product</label>
                        <InputText id="product" name="product" />
                    </div>
                </form>
            </Dialog>
        </>
    )
}

export default ManageReturn