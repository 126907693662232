import { Card } from 'primereact/card';
import React, { useState, useRef, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from '../../lib/axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import moment from 'moment';
import { Image } from 'primereact/image';



// return approve and reject page
const ReturnOrder = () => {

    const [loading, setLoading] = useState(false);
    const [orderLines, setOrderLines] = useState([]);
    const [returnOrders, setReturnOrders] = useState([]);
    const toasts = useRef(null);

    const [selectedLine, setSelectedLine] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [selectedLines, setSelectedLines] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const [fromDate, setFormDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [filteredCustomers, setFilteredCustomers] = useState(null);

    useEffect(() => {
        getReturnOrders();
    }, []);

    const getReturnOrders = (page = 1) => {
        setLoading(true);
        axios.get(`api/return-order`, {
            params: {
                page: page,
                customer_id: selectedCustomer?.id,
                fromDate: moment(fromDate).toISOString(true),
                toDate: moment(toDate).toISOString(true),
            }
        })
            .then(res => {
                setReturnOrders(res.data.data);
                setTotalRecords(res.data.total);
            }).catch(error => {
                console.error(error);
                alert('something went wrong')
            }).finally(() => setLoading(false));
    }

    const filterReturnOrder = () => {
        if (!selectedCustomer?.id) {
            toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'Customer field is required' })
            return;
        }

        if (!fromDate) {
            toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'From date field is required' })
            return;
        }

        if (!toDate) {
            toasts.current.show({ severity: 'error', summary: 'Error Message', detail: 'To date field is required' })
            return;
        }

        getReturnOrders();
    }

    const confirmReturn = (type) => {
        axios.post('api/return-order/approve', {
            type: type,
            order_id: 2,
            return_order_line_ids: selectedLines.map((item) => item.id)
        }).then(res => {
            setSelectedLines([]);
            toasts.current.show({ severity: 'success', summary: 'Success Message', detail: 'Action completed successfully' })
            getReturnOrders();
        }).finally(() => setIsDialogOpen(false))
    }

    // const returnBtn = rowData => <Button label="Return" onClick={e => openReturnModel(rowData)} />

    const openReturnModel = (rowData) => {
        setSelectedLine({
            ...rowData,
            ['damage']: false,
            ['refund']: false,
        });
        setIsDialogOpen(true);
    }

    // const returnItem = () => {
    //     if (selectedLine?.damage || selectedLine?.refund) {
    //         axios.post('api/return-order', {
    //             is_damage: selectedLine.damage,
    //             is_refund: selectedLine.refund,
    //             qty: selectedLine.item?.qty,
    //             order_line_id: selectedLine?.item.id,
    //         })
    //             .then(res => {
    //                 toasts.current.show({ severity: 'success', summary: 'Success Message', detail: res.data?.message });
    //                 setSelectedLine([]);
    //                 setOrderNumber('');
    //                 setIsDialogOpen(false);
    //             }).catch(error => {
    //                 toasts.current.show({ severity: 'error', summary: 'Error Message', detail: error.response?.data?.message })
    //             });
    //     }
    //     return;
    // }

    const viewItemsBtnBody = rowData => <span className='btn-view' onClick={() => viewOrderLines(rowData.id)} ><i className="pi pi-eye"></i></span>

    const viewOrderLines = (retrunId) => {
        setLoading(true);
        axios.get(`api/return-order-items/${retrunId}`)
            .then(res => {
                setOrderLines(res.data);
                setIsDialogOpen(true);
            }).catch(error => {
                alert('Something went wrong')
            }).finally(() => setLoading(false));
    }

    const damageBody = rowData => rowData.damage ? <i className="pi pi-check"></i> : <i className="pi pi-times"></i>;

    const isRowSelectable = rowData => rowData.data.status === 'processing';

    const dateBody = rowData => {
        let dateTimeUTC = new Date(rowData.created_at);
        const day = dateTimeUTC.getDate();
        const month = dateTimeUTC.getMonth() + 1;
        const year = dateTimeUTC.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const onPage = async (event) => {
        // let _event = {...event, ['page'] : event.page + 1};
        setLazyParams(event);
        getReturnOrders(event.page + 1);
    }

    const searchCustomers = (event) => axios
        .get('/api/customer', {
            params: {
                key: `%${event.query}%`
            }
        })
        .then((res) => {
            setFilteredCustomers(res.data);
        })
        .catch(error => {
            if (error.response?.status !== 409) throw error
        });

    const photoBody = rowData => rowData?.photo_url ? <Image src={rowData.photo_url} alt="photo" width="100" preview /> : null


    const returnOrderStatus = rowData => {
        const itemsCount = rowData?.order_lines.length;

        const processingCount = rowData?.order_lines.filter(order => order.status === 'processing').length;
        if(itemsCount === processingCount){
            return 'Processing';
        }
        const approvedCount = rowData?.order_lines.filter(order => order.status === 'approved').length;

        if(itemsCount === approvedCount){
            return 'Approved';
        }
        const rejectedCount = rowData?.order_lines.filter(order => order.status === 'rejected').length;

        if(itemsCount === rejectedCount){
            return 'Rejected';
        }

        return 'Partialy';
        
    }

    return (
        <>
            <Helmet>
                <title>Return Orders</title>
            </Helmet>

            <h2 className='mt-0 flex align-items-center'>Return Orders</h2>
            <div className='card-box'>
                <Card className='no-pb'>
                    {/* <div className="field col">
                    <label htmlFor="">Order Number</label><br />
                    <InputText id="order_number" className="w-full" value={orderNumber} onChange={(e) => setOrderNumber(e.target.value.toUpperCase())} onBlur={getOrderItems} />
                </div> */}
                    <div className="formgrid grid">
                        <div className="field col">
                            <label>Select Customer</label><br />
                            <AutoComplete className='w-full' value={selectedCustomer} suggestions={filteredCustomers}
                                completeMethod={searchCustomers} field="name"
                                onChange={(e) => setSelectedCustomer(e.value)} />
                        </div>
                        <div className="field col">
                            <label htmlFor="from Date">From Date</label><br />
                            <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='from_date' value={fromDate} onChange={(e) => setFormDate(e.value)} showIcon />
                        </div>

                        <div className="field col">
                            <label htmlFor="To Date">To Date</label><br />
                            <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='to_date' value={toDate} onChange={(e) => setToDate(e.value)} showIcon />
                        </div>
                        <div className="field flex align-items-end pl-2">
                            <Button label="Filter" type="submit" aria-label="Submit" style={{ marginTop: '5px' }} onClick={filterReturnOrder} />
                        </div>
                    </div>

                    <DataTable className='br-p-paginator-bottom' value={returnOrders} loading={loading} emptyMessage="No Returns"
                        paginator rows={lazyParams.rows} responsiveLayout="scroll"
                        first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}>
                        <Column body={dateBody} header="Date" />
                        <Column field="order.customer.name" header="Customer" />
                        <Column field="user.name" header="Agent" />
                        {/* <Column body={returnOrderStatus} header="Status" /> */}
                        <Column field="total_amount" header="Total Amount" />
                        <Column body={viewItemsBtnBody} header="View Items" />
                    </DataTable>
                </Card>
            </div>

            <Toast ref={toasts} />

            <Dialog header="Return Items" visible={isDialogOpen} onHide={() => setIsDialogOpen(false)} style={{ width: '550px' }}>
                <DataTable value={orderLines} loading={loading} selection={selectedLines}
                    onSelectionChange={e => setSelectedLines(e.value)}
                    dataKey="id" isDataSelectable={isRowSelectable} responsiveLayout="scroll" >
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="order_line.product.name" header="Item" />
                    <Column field="qty" header="Qty" />
                    <Column body={damageBody} header="Damage" />
                    <Column field="reason" header="Reason" />
                    <Column field="status" header="status" />
                    <Column body={photoBody} header="Photo" />
                    <Column field="sold_price" header="Price" />
                </DataTable>

                <Divider />

                <div className="flex justify-content-end">
                    <Button label='Reject' icon="pi pi pi-times" disabled={selectedLines.length === 0} className="mr-3 p-button-icon p-button-danger p-button-rounded" onClick={() => confirmReturn('rejected')} />
                    <Button label='Approve' icon="pi pi-check" disabled={selectedLines.length === 0} className="p-button-icon p-button-success p-button-rounded" onClick={() => confirmReturn('approved')} />
                </div>

            </Dialog>
        </>
    );
}

export default ReturnOrder;
