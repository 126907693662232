import { useFormik } from 'formik'
import { Card } from 'primereact/card'
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react'
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ManageReturn from './ManageReturn';


// return order
function Return() {
    const [showDialog, setshowDialog] = useState(false);
    const [product_data, setProductData] = useState(false);

    const formik = useFormik({
        initialValues: {
            invoice_number: ''
        },
        validate: (data) => {
            let errors = {};
            if (!data.invoice_number) {
                errors.invoice_number = 'Invoice number required'
            }
            return errors;
        },
        onSubmit: (data) => {
            console.log(data);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const details = rowData => <Button className="p-button-secondary" onClick={() => updateDetails(rowData)}><i className="pi pi-user-edit"></i></Button>
    const updateDetails = (data) => {
        setProductData(data);
        setshowDialog(true);
    };

    const hideCreateCustomerDialog = (success) => {
        setshowDialog(false)
    };



    const productData = [
        { purchase: "Bag", quantity: '1', price: '1200', },
    ]

    return (
        <>
            <Card title="Return">
                <form onSubmit={formik.handleSubmit}>
                    <div className="card p-fluid">
                        <div className="formgrid grid">

                            <div className="field col">
                                <span className="p-float-label">
                                    <InputText id="invoice_number" name="invoice_number" value={formik.values.invoice_number} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('invoice_number') })} />
                                    <label className={classNames({ 'p-error': isFormFieldValid('invoice_number') })}>Invoice number</label>
                                </span>
                                {getFormErrorMessage('invoice_number')}
                            </div>

                            <div className="field col " style={{ marginTop: '-10px' }}>
                                <Button type="submit" label="Submit" className="mt-2" style={{ width: '100px' }} />
                            </div>
                        </div>
                    </div>
                </form>

                <br />
                <div>
                    <DataTable value={productData} responsiveLayout="scroll" emptyMessage="No transactions found." >
                        <Column field="purchase" header="Purchase">Bag</Column>
                        <Column field="quantity" header="Quantity">1</Column>
                        <Column field="price" header="Price">1200</Column>
                        <Column field="details" body={details} header="Details"></Column>
                    </DataTable>
                </div>
                {showDialog && <ManageReturn isOpen={showDialog} onHide={hideCreateCustomerDialog} />}

            </Card>
        </>
    )
}

export default Return