import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ManageSupplier from './ManageSupplier';
import { Toast } from 'primereact/toast';

// create supplier page
function Supplier() {
    const [supplierData, setSupplierData] = useState([]);
    const [showDialog, setshowDialog] = useState(false);
    const [editflag, setEditFlag] = useState(false);
    const [suplierDetails, setSuplierDetails] = useState();
    const [loading, setLoading] = useState(false);


    const getSupplierData = () => {
        axios.get(`api/suppliers`)
            .then(res => {
                setSupplierData(res.data);
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true);
        getSupplierData();
        return(()=>{
            setSupplierData([]);
        })
    }, [])

    const hideSupplierDialog = (success) => {
        if (success === 'Created') {
            showSuccess();
        } else if (success === 'Error') {
            showError();
        }
        setshowDialog(false);
        getSupplierData();
    };

    const editSupplier = rowData => <span className="btn-view" onClick={() => { setEditFlag(true); updateSupplier(rowData) }}><i className="pi pi-user-edit"></i></span>

    const updateSupplier = (data) => {
        setSuplierDetails(data);
        setshowDialog(true);
    };

    const toast = useRef(null);

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Successfull', detail: 'Action Completed', life: 3000 });
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: 'Error ', detail: 'Unexpected Error', life: 3000 });
    }

    return (
        <>
            <div className='flex justify-content-between mb-4'>
                <h2 className='mt-0 flex align-items-center mr-auto'>Suppliers</h2>
                <Button label="Create New" icon="pi pi-plus" iconPos="left" onClick={() => { setEditFlag(false); return setshowDialog(true) }} />
            </div>
            <div className='card-box'>
            <Card className='no-px-2'>
                <div className="col-12 p-0">
                    <div>
                        <DataTable className='datatable-rounded' value={supplierData} header='' responsiveLayout="scroll" emptyMessage="No Suppliers found." loading={loading} >
                            {/* <Column field="id" header="Id"></Column> */}
                            <Column field="name" header="Name"></Column>
                            <Column field="Action" body={editSupplier} header="Details"></Column>
                        </DataTable>
                    </div>
                </div>

                {showDialog && <ManageSupplier isOpen={showDialog} onHide={hideSupplierDialog} editflag={editflag} details={suplierDetails} />}
            </Card >
            </div>
            <Toast ref={toast} />

        </>
    )
}

export default Supplier; 