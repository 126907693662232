import axios from '../../lib/axios';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState } from 'react'
import { Formik, useFormik } from 'formik';
import ManageProductDetails from './ManageProductDetails';
import moment from 'moment';
import { AutoComplete } from 'primereact/autocomplete';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

function PurchaseDetails() {
    const isSuperAdmin = useSelector(e => e.issuperAdmiReducer).isSuperAdmin;

    const [result, setResult] = useState();
    const [loading, setLoading] = useState(false);
    const [showDialog, setshowDialog] = useState(false);
    const [product_data, setProductData] = useState(false);

    const [selectedShop, setSelectedShop] = useState(null);
    const [shop, setShop] = useState([]);
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');

    const searchshop = (event) => {
        let _filteredShop;
        axios.get(`/api/shop?key=${event.query}`)
            .then(res => {
                _filteredShop = res.data.filter((e) => {
                    return e.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
                setShop(_filteredShop);
            })
            .catch(error => {
                if (error.response?.status !== 409) throw error
            });
    }


    const handleSubmit = (data) => {
        setLoading(true);
        let from_date = moment(data.from_date).toISOString(true);
        let to_date = moment(data.to_date).toISOString(true);
        axios.get(`/api/purchases`, {
            params: {
                data: {
                    type: "purchases",
                    attributes: {
                        supplier: selectedSupplier?.id,
                        shop: selectedShop?.id,
                        from_date: from_date,
                        to_date: to_date
                    }
                }
            }
        })
            .then(res => {
                setResult(res.data.data);
                setLoading(false)
            })
            .catch(error => console.log(error))
    }
    const formik = useFormik({
        initialValues: {
            from_date: null,
            to_date: null
        },
        validate: (data) => {
            let errors = {};

            if (isSuperAdmin) {
                if (!shop) {
                    errors.shop = 'Branch required'
                }
            }
            if (!selectedSupplier) {
                errors.supplier = 'supplier required'
            }
            if (data.from_date === null) {
                errors.from_date = 'date required'
            }
            if (data.to_date === null) {
                errors.to_date = 'date required'
            }

            return errors;
        },
        onSubmit: (data) => {
            handleSubmit(data)
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const purchaseDetail = rowData => <Button icon="pi pi-bookmark" className="p-button-rounded p-button-secondary" onClick={() => updatePurchase(rowData)}> </Button>

    const updatePurchase = (product) => {
        setProductData(product);
        setshowDialog(true);
    };

    const searchSupplier = (e) => {
        axios.get('api/suppliers', {
            params: {
                key: e.query
            }
        })
            .then(res => {
                setFilteredSuppliers(res.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const hideCreateCustomerDialog = (success) => {
        setshowDialog(false)
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Purchase Details</title>
                </Helmet>
            </div>
            <h2 className='mt-0 flex align-items-center mr-auto'>Purchase Details</h2>
            <div className='card-box'>
            <Card className='no-pb br-p-datatable-wrapper'>
                <div className="col p-0">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="formgrid grid">
                            {
                                isSuperAdmin &&
                                <div className="field col mb-0">
                                    <label htmlFor="name1">Select Branch</label>
                                    <AutoComplete className='w-full' placeholder="Search Branch" inputId='select_shop' name='shop' value={selectedShop} suggestions={shop} completeMethod={searchshop} field="name" onChange={(e) => setSelectedShop(e.value)} />
                                    {getFormErrorMessage('shop')}
                                </div>
                            }

                            <div className="field col mb-0">
                                <div className="field">
                                    <label htmlFor="name1">Select Supplier</label>
                                    <AutoComplete className='w-full' placeholder="Search Supplier" inputId='select_supplier' name='supplier' value={selectedSupplier} suggestions={filteredSuppliers} completeMethod={searchSupplier} field="name" onSelect={(e) => setSelectedSupplier(e.value)} onChange={(e) => setSelectedSupplier(e.value)} />
                                    {getFormErrorMessage('supplier')}
                                </div>
                            </div>

                            <div className="field col mb-0">
                                <label htmlFor="from Date">From Date</label>
                                <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='from_date' value={formik.values.from_date} onChange={formik.handleChange} showIcon />
                                {getFormErrorMessage('from_date')}
                            </div>

                            <div className="field col">
                                <label htmlFor="To Date">To Date</label>
                                <Calendar className='w-full' id="icon" dateFormat="dd/mm/yy" name='to_date' value={formik.values.to_date} onChange={formik.handleChange} showIcon />
                                {getFormErrorMessage('to_date')}
                            </div>

                            <div className="field flex align-items-end pl-2">
                                <Button label="Submit" type="submit" aria-label="Submit" style={{ marginTop: '5px' }} />
                            </div>

                        </div>
                    </form>


                    <div className="formgrid">
                        <DataTable value={result} responsiveLayout="scroll" emptyMessage="No Purchase Details found." loading={loading} >
                            {/* <Column field="id" header="id"></Column> */}
                            <Column field="invoice_id" header="Invoice Id"></Column>
                            <Column field="supplier" header="Supplier"></Column>
                            <Column field="date" header="Date"></Column>
                            <Column field="entered_by" header="Entered by"></Column>
                            <Column field="invoice_amount" header="Invoice Amount"></Column>
                            <Column field="Action" body={purchaseDetail} header="Show Details"></Column>
                        </DataTable>
                        {showDialog && <ManageProductDetails isOpen={showDialog} onHide={hideCreateCustomerDialog} product_data={product_data} />}

                    </div>
                </div>
            </Card>
            </div>
        </>

    )
}

export default PurchaseDetails