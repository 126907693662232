
const usermenu = [
    {
        label: 'Home',
        items: [{
            label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'
        }]
    },
    {
        label: 'Orders', icon: 'pi pi-fw pi-sitemap',
        items: [
            { label: 'Create Order', icon: 'pi pi-fw pi-pencil', to: '/order/create' },
            { label: 'Orders', icon: 'pi pi-fw pi-shopping-cart', to: '/orders' },
            { label: 'Consolidate', icon: 'pi pi-fw pi-sitemap', to: '/order/consolidate' },
            { label: 'Return', icon: 'pi pi-fw pi-replay', to: '/order/return' },
        ]
    },
    {
        label: 'Stock Transfer', icon: 'pi pi-fw pi-sitemap',
        items: [
            { label: 'Stock Transfer', icon: 'pi pi-fw pi-sort-amount-up-alt', to: '/stock-transfer' },
            { label: 'Stock Transfer Details ', icon: 'pi pi-fw pi-chart-line', to: '/stock-transfer-details' },

        ]
    },

    {
        label: 'Customers',
        items: [
            { label: 'Customer', icon: 'pi pi-fw  pi-users', to: '/customers' },
            { label: 'Customer Ledger', icon: 'pi pi-fw pi-folder-open', to: '/customer-ledger' }
        ]
    },

    {
        label: 'Products', icon: 'pi pi-fw pi-sitemap',
        items: [
            { label: 'Manage Products', icon: 'pi pi-fw pi-check-square', to: '/products/create' },
        ]
    },
    {
        label: 'Purchase Management', icon: 'pi pi-fw pi-sitemap',
        items: [
            { label: 'Purchase', icon: 'pi pi-fw pi-shopping-bag', to: '/inventory' },
            { label: 'Purchase Details', icon: 'pi pi-fw pi-tag', to: '/purchase-details' },
            { label: 'Invoice', icon: 'pi pi-fw pi-file', to: '/invoices' }
        ]
    },

    {
        label: 'Supplier',
        items: [
            { label: 'Supplier', icon: 'pi pi-fw pi-briefcase', to: '/supplier' },
        ]
    },

    {
        label: 'Damage',
        items: [
            { label: 'Damage Entries', icon: 'pi pi-fw pi-exclamation-triangle', to: '/damage-entries' },
        ]
    },

    {
        label: 'Shop User',
        items: [{
            label: 'Shop User', icon: 'pi pi-fw pi-user', to: '/users'
        }]
    },



    // {
    //     label: 'Get Started',
    //     items: [
    //         { label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => { window.location = "#/documentation" } },
    //         { label: 'View Source', icon: 'pi pi-fw pi-search', command: () => { window.location = "https://github.com/primefaces/sakai-react" } }
    //     ]
    // },

    {
        label: 'Currencies',
        items: [
            { label: 'Currencies', icon: 'pi pi-dollar', to: '/currencies' },
        ]
    },
    // {
    //     label: 'Return',
    //     items: [
    //         { label: 'Return', icon: 'pi pi-shopping-cart', to: '/return' },
    //     ]
    // },

    {
        label: 'Reports',
        items: [
            { label: 'Customer Ledger', icon: 'pi pi-fw pi-folder-open', to: '/customer-ledger' },
            { label: 'Payment History', icon: 'pi pi-fw pi-history', to: '/payments/history' },
            { label: 'Purchase Details', icon: 'pi pi-fw pi-tag', to: '/purchase-details' },
            { label: 'Stock Transfer Details ', icon: 'pi pi-fw pi-chart-line', to: '/stock-transfer-details' },
            { label: 'Damage Entries', icon: 'pi pi-fw pi-exclamation-triangle', to: '/damage-entries' },
        ]
    },
    {
        label: 'Download PDF',
        items: [
            { label: 'Customer Ledger', icon: 'pi pi-fw pi-folder-open', to: '/download/customer-ledger' },
        ]
    }
]

export { usermenu }; 