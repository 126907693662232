//import { use } from "browser-sync";

let initialState = {
    user: window.user
    //user: window
};


function isSuperAdmiReducer(state = initialState, action) {
    if (state?.user?.role === "Super Admin") {
        return {
            isSuperAdmin: true,
            data: state
        };
    } else if (state?.user?.role === "Admin") {
        return {
            isSuperAdmin: false,
            data: state
        };
    } else {
        return state;
    }
    // switch (action.type) {
    //     case true:
    //         return state = true;
    //     case false:
    //         return state = false;
    //     default:
    //         console.log('action : ', action)
    //         return state
    // }
};

export default isSuperAdmiReducer;